(function(){

if(typeof dsb === 'undefined') dsb = { log: function(m){ if(console && console.log) console.log(m); } };

/*********************************************************************/
// [ dsb -> commands ] :: functions
/*********************************************************************/

dsb.fn = {  


  /* @method defined
   * Returns false if var is undefined or null
   * @param {any} var
   * @returns {Boolean}
   */
  defined: function(v){
    switch(typeof v){ case null: case 'undefined': return false; break; default: return true; break; }
  },


  /* @method undefined
   * Returns true if var is undefined or null
   * @param {any} var
   * @returns {Boolean}
   */

  undefined: function(v){
    return !this.defined(v);
  },


  /* @method isset
   * Returns true if var is undefined, null, or an empty string
   * @param {any} var
   * @returns {Boolean}
   */

  isset: function(v){
    return ( dsb.fn.defined(v) && v!=='' );
  },


  /* @method isString
   * is the variable a string?
   * @param {any} var
   * @returns {Boolean}
   */    

  isString: function(v){ 
    return ( typeof v === 'string' );
  },    


  /* @method isArray
   * is the variable an array?
   * @param {any} var
   * @returns {Boolean}
   */

  isArray: function(a){
    var s = false;
    if( Object.prototype.toString.call( a ) === '[object Array]' ) s = true;
//    if( this.isObjectArray(a) ) s = true;
    if(Array.isArray) s = Array.isArray(a);
    if( a instanceof Array ) s = true; 
    return s;
  },


  /* @method isObject
   * is the variable an object?
   * @param {any} var
   * @returns {Boolean}
   */

  isObject: function(o){
    var t = ( dsb.fn.defined(o) ) ? typeof o : null;
    return( o!==null && t==='object' && t!=='undefined' ); // && t===Object(t) 
  },


  /* @method isObjectArray
   * is var a plain JS object? *excludes Array, jQuery, HTMLCollection, HTMLElement, and NodeList
   * @param {any} var
   * @returns {Boolean}
   */

  isObjectArray: function(o){
    if( window && o === window ) return false; //hopefully fixes cyclic/overflow
    var excluded = ['[object HTMLCollection]','[object HTMLElement]','[object NodeList]'],
    fn = dsb.fn,
    check = function(o){      
      var r = false; 
      //alert( fn.objectType );
      for( var i in excluded ) 
        if(fn.objectType(o)===excluded[i]) 
          r = true; //is it excluded?
      return r;
    },
    safe = false;

    if( fn.isObject(o) && !fn.isjQuery(o) ){      
      //console.log( 'found illegal element');
      if( check(o) ) return false;      
      
      for( var i in o ) {
        if ( fn.isFunction( o[i] ) ) safe = true;
      }
      var s = function(o){
        var cache = [];
        JSON.stringify(o, function(key, value) {
          if (typeof value === 'object' && value !== null) {
            if (cache.indexOf(value) !== -1) {
              // Circular reference found, discard key
              return;
            }
            // Store value in our collection
            cache.push(value);
          }
          return value;
        });
        cache = null; // Enable garbage collection
      };
      if(  !fn.isArray(o) && ( o != {} || safe )  ) return true; //s(o)!=='{}' || 
    } 
    return false;     
  },


  /* @method isFunction
   * is the variable a function
   * @param {any} var
   * @returns {Boolean}
   */

  isFunction: function(o){
    return ( typeof o === 'function' );
  },


  /* @method isBoolean
   * is the variable a true boolean (must be true || false)
   * @param {any} var
   * @returns {Boolean}
   */

  isBoolean: function(o){
    return (o===true || o === false);
  },


  /* @method isjQuery
   * is the variable a jQuery object?
   * @param {any} var
   * @returns {Boolean}
   */

  isjQuery: function(o){
    return typeof jQuery === 'function' && (o instanceof jQuery);
  },


  /* @method objectType
   * is the same as Object.prototype.toString
   * @param {any} var
   * @returns {Boolean}
   */

  objectType: function(o){
    return Object.prototype.toString.call( o );
  },


  // # TODO : Remove this after checking if it's used
  protoStringCall: function(v){
    return Object.prototype.toString.call( v );
  },


  /* #TODO : Retire this function
   * @method getTypes
   * returns an object of types (i.e. { a: 'something', b: ['red','blue'] } == { a: String, b: Array }
   * @param {type} a
   * @returns {object}
   */

  getTypes: function(a){ //typeof each
    var o = {}; for(var i in a) o[i] = typeof a; return o;
  },


  /* @method solidify
   * receives an object, searches for o[target] recursively, adds o[alias] = jQuery( o[target] )
   * @param {Object} object
   * @param {String} [alias] @default: 'self'
   * @returns {Object} 
   */

  solidify: function(o){
    var a = this.isObjectArray;    
    if(a(o)){
      
      for(var i in o){
        var x = o[i];
        if(a(x) && x!==null) x.self = $(x.target);	  // could work in a JS option
        if(a(x.wrap)) dsb.fn.solidify(x);
      }
    }
    return o;
  },


  /* @method whatis
   * 
   * @param {any} it
   * @returns {}
   */

  whatis: function(it){
    var types = {
      string: 'isString', object: 'isObject', array: 'isArray', object_array: 'isObjectArray', jQuery: 'isjQuery', function: 'isFunction', boolean: 'isBoolean'
    }, type = null;
    for(var t in types){
//      dsb.log('checking if '+t+', calling '+ types[t]);
      var x = types[t];
      if( dsb.fn[x](it) ) type = t;
    }

    /*
    dsb.log('it is [ '+type+' ]');
      var s = 'string';
      var b = false;
      var o = document.getElementById('header');
      var jqo = $('header');

      var oa = { test:true };

      var a = [ 'array' ];
      var w = function(){ alert( dsb.fn.whatis(oa) ) };

      var n = null;
      var fn = function(){ }
      w(ao);
    /**/
    return type;
  },


  describe: function(o){
    var t = this.whatis( o ),
    s = 'Type: '+ t,
    somefun = dsb.log,
    delve = function(){
      var is = 'Contents: ';
      is += o.toString();
      s += '\n'+is;
    },
    c = {
      'array': somefun,
      'string':somefun,
      'object_array': delve,
    };
    for(var key in c) if( t === key ) c[key]();
    return s;

  },


  winData: function(){
    var f = {
      t: window.pageYOffset,
      l: window.pageXOffset,
      w: window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth,
      h: window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight
    };
    f.b = (function(){ return(f.t+f.h); }());
    f.r = (function(){ return(f.l+f.w); }());
    return f;
  },

  offsets: function(o){
    var t = o || this;
    if( window && t === window ) return dsb.fn.winData();
    var old = function(){
      var i = $(t);
      var f = {};
        f.t = i.offset().top,
        f.l = i.offset().left,
        f.w = i.width(),
        f.h = i.height(),
        f.b = (function(){ return(f.t+f.h); }());
        f.r = (function(){ return(f.l+f.w); }());
      return f;  
    };
    var _new = function(element){
      if( typeof jQuery !== 'undefined' && element instanceof jQuery ) element = element[0];
      var bod = document.body.getBoundingClientRect(),
          el = element.getBoundingClientRect(),
          offset = function(dir){
            return el[dir] - bod[dir];
          };
      var t = offset('top'),
          l = offset('left'),
          b = offset('bottom'),
          r = offset('right'),
          w = r - l,
          h = b - t;
      var x = { t:t, l:l, b:b, r:r, w:w, h:h };
      console.log(x);
      return x;
    };
    return old(t);
  },

  between: function(b1,b2,n){ //accepts 3 numbers (min, max, n)
   return(b1 < n && n < b2); //is n between b1 and b2?
  },


/*
collides works, i had args backwards. need to add 3 options for x, y, both
*/


  collides: function(object ,container, axis){ //accepts 
//    dsb.log('checking for collision')
    var a = object, b = dsb.fn.between, c = container, collides = false,
    x = ( b(c.l,c.r,a.l) || b(c.l,c.r,a.r) ), //does x axis cross?
    y = ( b(c.t,c.b,a.t) || b(c.t,c.b,a.b) ); //does y axis cross?    
    if(axis==='x' && x) return true;
    if(axis==='y' && y) return true;
    return(x && y); //objects collide? [boolean]
  },


// [ array / object_array processing ]

  toArray: function(o){ var a = [], o = o || this; for(var i in o) a.push(o[i]); return a; },

  print_r: function(array,recursive,level){
    var results = '';
    //formatting
    var process = function(array,recursive,level){
      var level = level || 0, spacer = '&nbsp;&nbsp;', breaker = '<br>', spacing = '';  
      //spacing
      for(var l = 0; l<level; l++) spacing += spacer;

      //interpolation and wrap handlers
      var b = breaker, s = spacing, wrap = { 
        'object': function(i,o,last){ return s+i+': {'+s+b+' '+o+' '+(b+s)+' },'+b; },
        'string': function(s){ return ' "'+s+'" '; }, 
        'array': function(a){ return ' ['+i+'] '; }
      };      

      //parse array / object
      if( dsb.fn.isObject( array ) ){
        var truearray = dsb.fn.toArray(array), i = 0, l = truearray.length;
        //format properties
        for(var key in array){
          i++;
          var last = (i===l),
          value = recursive ? process( array[key], false, level+1) : array[key]; //determine value
                dsb.log(i+'|'+key+'='+last);
          results += wrap['object'](key,value,last); // wrap in object format
        }  
      } else { 
        //handle non-arrays
        var type = dsb.fn.whatis(array);      
        results += spacing + ( ( type in wrap ) ? wrap[type](array) : '(non-array) '+array ); 
      }
      return s;    
    }
    process(array,recursive,0);
    return results;
  },

  compareArrays: function(a1,a2){ // compares 2 true arrays ( [] )
    var s = true;
    for(var i in a1){  if(s){ if( a1[i] !== a2[i] ) s = false; } }
    return s;
  },

  clone: function(object){
    var n = new Object;
    for(var i in object) n[i] = object[i];
    return n;
  },
  override: function(a1,a2){  // override existing dataset in a1 with a2 (leaves existing in a1)
    var a = this.isObjectArray, o = this.clone(a1);
    if( a(a1) && a(a2) ){
      for(var key in a2) o[key] = a2[key];
    }
    return o;
  },

  compareObjects: function(o1,o2){ //compare object_arrays
    var a1o = new Array, 
    a1p = new Array,
    a2o = new Array,
    a2p = new Array,  
    same = true;
    for(var k in o1){
      if(o1.hasOwnProperty(k)){
        a1o.push(k);
        a1p.push(o1[k]);
      }
    }
    for(var k in o2){
      if(o2.hasOwnProperty(k)){
        a2o.push(k);
        a2p.push(o2[k]);
      }
    }  
    for(var i=0; i<a1o.length; i++){
      if(a1o[i]!==a2o[i]) same = false;
    }
    for(var i=0; i<a2o.length; i++){
      if(a2p[i]!==a2p[i]) same = false;
    }  
    return(same);
  },

  compare: function(a1,a2,pre){ // compare anything. optional pre() to modify each prior to compare
    var f = dsb.fn;
    if( f.isFunction(pre) ){
      a1 = pre(a1); a2 = pre(a2);
    }
    if(typeof a1 === typeof a2){
      if( f.isString(a1) ){
        if(a1===a2) return true;
      }
      var a = f.isObjectArray;
      if( a(a1) && a(a2) ){
        return f.compareObjects(a1,a2);
      }
    }
    else return false;
  },



  spoof: function(o,options){ // creates empty shell clone of object. options: target (to attach to), hidden, absolute (takes no space if hidden)
    //$$$=this;
    var d = { target:null, hidden:false, absolute:false, float: false, reverse: false },
    p = dsb.fn.override(d,options);

    var o = $(o),
    w = o.outerWidth(true),
    h = o.outerHeight(true),
    x = dsb.fn.offsets(o),
    w = x.w,
    h = x.h,
    s = document.createElement('div');
    s.style.width = w+'px';
    s.style.height = h+'px';
    s.className = 'spoof';
    s.style.zIndex = 0;
    o.css('z-index',1);
    if(p.hidden) s.style.visibility = 'hidden';
    if(p.absolute){
      var a = ['top','bottom','left','right'];
      s.style.position = 'absolute';
      for(var i in a) s.style[a[i]] = 0;
    }
    if( p.float ){
      if( p.reverse ) s.style.float = 'right';
      else s.style.float = 'left';
    }
    if(p.target) p.target.append(s); //{ alert('appending '+o+' to '+t); t.append(s); }
    else o.after(s);
    return s;
    //dbm.log('spoofed an element:'+ o.prop('tagName'),15);
    //alert('spoofed one '+o.prop('tagName')+' w:'+w);
  },

  // [ random generators ]

  random: {

    number: function(min,max,integer){
      var r = Math.random()  * (max - min) + min;
      if(integer) r = parseInt(r);
      return r;
    },

    color: function(method){
      var methods = {
        rgb: function(){},
        rgba: function(){},
        hex: function(){
          var s = '#';
          for(var i = 0; i<3; i++) s += dsb.fn.random.number(0,9,true);
          return s;
        }
      };
      //alert( methods['hex']() );
      var output = false,
      method = method || ( dsb.site.colors.format || 'hex' );
      if( method in methods ) output = methods[method]();
      return output;        
      //if( method in methods ) methods[method]() 
    }//end color

  },

  test: function(m){ alert( m || 'fired from dsb.fn'); }

};
//dsb.fn.printr = dsb.fn.print_r;
/*********************************************************************/
if( typeof module !== 'undefined' ) module.exports = dsb.fn;
/*********************************************************************/
}());