/*********************************************************************/
// for unit testing
/*********************************************************************/
if(typeof dsb === 'undefined') dsb = { log: function(m){ console.log(m); } };

/*********************************************************************/
// [ DOM Animation Controls ]
/*********************************************************************/
dsb.animation = {
  
  options: {  
  },
  
  buffer: 500,
  busy: false,
  loop: false,

  index: 0, //unique animation identifier  
  roster: [], //full list of animations
  active: [],  //animations currently running
  queues: {}, //holds all queued animations by trigger  
  // object = passes animation to queue. string = return queue if exists. blank = return all queues as string.
  queue: function(animation){ //breaks queues into sub-queues(arrays) by triggers
    var t = typeof animation;        
    if( !animation ) return this.queues.toString();
    else {
      if(t==='object'){
        dsb.log('adding '+animation.id+' to queue '+animation.trigger,1,10);
        if( !this.queues[ animation.trigger ] ) this.queues[ animation.trigger ] = [];
        this.queues[ animation.trigger ].push(animation);      
      }
      if(t==='string'||'number') return this.queues[ animation ] || false;
    }
  }, 
  complete: [], //animations that have completed (dom node no longer moving)

  last: false,
  current: false,
  next: false,

  // [ setup ] :: adds libraries and finds .animate objects

  setup: function(libraries){ //expects array
//  alert(logg)
    dsb.logger.group.assign(10,'animation');
    //seeks out files/css/libraries/(name) .css && .json containing all effect classnames
    for(var name in libraries) this.addLibrary(libraries[name]); 
    //seeks out DOM nodes to animate
    var self = this,
    nodes = document.querySelectorAll('.animate');
    dsb.log('%large: Found '+nodes.length+' nodes to animate',1,10);
    for(var i in nodes){
      self.inspect( nodes[i] );
    }
    self.start();
  },  

  
  // [ inspect ] :: validates and translates node[data-animate] for add()
  inspect: function(node){
    if( dsb.fn.objectType(node).contains('HTML') ){
      //0:trigger, 1: effect [2: duration, 3: delay, 4: iterations, 5: alternate]
      var x = node, d = x.getAttribute('data-animate'), a = d.split(' '), l = a.length, i = this.index++, animation = { trigger: a[0], effect: a[1], id: i, node: x };
      x.setAttribute('data-animation',i); //for greater control      
      if(l>2){}//TODO: setup to deal with mulitple params              
      this.add(animation);
    }    
  },
  


  // [ add ] :: adds animation to roster and processes animation
  add: function(animation){
    this.roster[ animation.id ] = animation;
    this.process(animation);
  },    
  
  // [ process ] :: appropriate trigger and queue animation
  process: function(animation){    
    dsb.log('processing animation '+animation.id);
    var events = {
//      load : function(){ this.handle( 'activate', animation ); }, //activate immediately
      last : function(){ //execute with last
        animation.trigger = animation.id-1;
      }
    };

    //trigger from registered events
    if( animation.trigger in events ){
      dsb.log('found registered event. invoking method '+animation.trigger,10,10);
      events[ animation.trigger ].call(this);
    }
    //delegate trigger to other events
    else {
      dsb.log('method '+animation.trigger+' delegated ',5,10);
//      this.queue(animation);
      $(animation.node).on(animation.trigger,function(){
        this.handle( 'activate', animation );
      });
    }
    //add to queue
    this.queue(animation);
    // animations are now queued. animations with trigger for events that have passed are now activated.
  },
  
    
  // [ handles ] :: handles physical DOM manipulation for animate nodes
  handles: {

    'activate': function(animation){          
//      $$=('animation found from activate: ' + animation)
      var c = animation.node.classList;
      if( c.contains('hide') ) c.remove('hide');            
      c.add(animation.effect);

      this.protect(animation);
  
      //for chaining
      var q = this.queue(animation.id);
      // if( q ) this.run( q );
      // else dsb.error('Could not find queued arrays');
      
    }
    
  }, //end handles          
  
  // [ handle ] :: invoke handler
  handle: function(action,animation){
    if(action in this.handles) this.handles[action].call(this,animation);
    else dsb.error('Provided action is not registered in dsb.animation.handlers!');
  },
  
    
  // [ run ] activates all animations in a given queue
  run: function(queue){
    dsb.log('running queue: '+queue,6,10);
    for(var animation in queue) this.handle( 'activate', queue[animation] );
  },      


  // [ start ]
  start: function(){
    this.run( this.queue('load') );
  },
    




  //temporarily locks object. sets to current??  
  protect: function(animation){
    var i = animation.id;
    dsb.log('protecting animation #'+i,15,10);
    this.busy = true;
    this.active.push(i);
    this.current = i;
    animation.node.onended = function(){
    //  alert('animation ended');
    };
  },

  //release if complete
  release: function(i){
    delete this.active[i];
    if(!this.active.length) this.busy = false;
  },
  //take away live animation from node
  remove: function(o){
    var a = o.data('animation');
    o.removeClass( a );    
  },

  
  stop: function(i){
  //  dsb.log('stopping');
    clearTimeout(loop);
    release(i);
  },



  // [ Library Control ]
  
  libraries: 'files/css/libraries/',
  library: {},  
  addLibrary: function(name){
    dsb.log('adding animation library '+name,2,10);
    var x = this,
    path = x.libraries+name+'.json';
    $.ajax(path, {
      dataType:"json",
      success: function(r){  
        x.library[name] = r[name]; 
       },
       error: function(e){
         var s = '';
         for(var i in e) s += i+' : '+e[i]+' \n\n';
//         alert('Could not find animation library: '+name);
     }
   });
    /**/
  },


/*********************************************************************/
// [ travel ] :: creates and handles arrive/depart functionality
/*********************************************************************/  
  
  traveler: {

    options: {
      visa: 'arrival-point', //class name of passport
      offset: .5, // 50%
      margin: .2, // 20% each way
      cooldown: 250, //cooldown between allowing arrival again
      showGuides: true
    },
        
    travelers: [],    
    
    map: {}, //area that activates when traveling
    world: {}, //window
    
    traveling: false,
    explore: function(){ var x = this; x.traveling = true; setTimeout( x.rest, x.options.cooldown ); },
    rest: function(){ this.traveling = false; },

    travel: function(){

      this.world.t = window.pageYOffset;
      
      if( !this.traveling ){
        this.explore(); //activates the cooldown timer
        this.process(); //process all travelers on the map
        dsb.log('Look at me, I\'m going places!');
      }
    },
    
    pack: function(options){  //creates map, checks for arrivals

      var self = this,
      globe = self.world = dsb.fn.winData(),      
      options = options || this.options, //dsb.fn.override( self.options, options || {} ),
      map = {
        h: ( globe.h * (options.margin*2) ) / 2,
        t: ( globe.h * (options.offset-options.margin) )
      };
      
      //TODO: work out better system for checking travelers 1st trip
      $('body').find('.arrive').each(self.process);
            
      $(window).on('scroll',self.travel.bind(self)); //end on.scroll
      
      if(options.showGuides){
        //create visual guide of band
        var p = $h('div', {
          style: {  
            class: options.visa, position: 'fixed', width:'100%', height:map.h*2+'px', 
              top: map.t+'px', background: 'rgba(255,0,0,.2)', z_index:100
          }
        }, '');
        //append guide band
        document.body.appendChild( p );
      }

    }, //end pack
  
    //processes each traveler. 1st time, gathers and stores offset data
    
    //check passport
    check: function(passport,traveler){
      var registered = passport = this.travelers[passport];
      if( !registered )  passport = dsb.fn.offsets(this); //stamp passport
      return passport;
    },
    
    process: function(passport, traveler){
      var legal = this.check(passport,traveler);
      if(legal){               
        var c = dsb.fn.collides( dsb.fn.offsets(passport), this.travelers[passport], 'y' ); //check if object is inside band
        if( c ){
          dsb.log( 'collides? ' + c  );
          $(traveler).trigger('arrive');
        }
      } else dsb.log('Traveler '+passport+' detained, could not verify passport.');
    
    }//end process   $('.arrive').each(process);
  
  },//end travel
  
  

/*********************************************************************/
// [ showcase ] :: creates an example of every effect in the lib
/*********************************************************************/  
  
  showcase: function(lib,preview){
    this.setup(lib);
    var library = this.library[lib],
    tiles = [],
    $h = dsb.html.create;

    dsb.log('Showcasing '+lib+' :: '+library.length+' animations found');
    
    var factors = ['In','Out'], //sort factors
    sorted = false,
    assorted = [],
    remaining = library;
    
    //sort libary in order of factors (0,1,2,remaining)
    sortLibrary = function(){
      var assort = function(term){
        for(var effect in library){
          var animation = library[effect];
          var i = animation.indexOf(term);
          if( i > -1 ){ 
            assorted.push( animation );
            delete remaining[effect];
          }
        }//end for
      }//end assort
      for(var keyword in factors) assort( factors[keyword] );
      for(var i in remaining){ assorted.push(remaining[i]); }
      sorted = true;
    };//end sortLibrary()

    sortLibrary();

    //handle special cases (only 1st case met is currently returned)
    var special = function(animation){      
      var background,
      cases = {
        'In': 'indigo',
        'Out': 'orange',
        'Up': 'ultraviolet',
        'Down': 'darkgray',
        'Left': 'lemon',
        'Right': 'red'
      };
      for(var i in cases) if( animation.contains(i) ) return cases[i];
    }//end special()
    
    //create tiles (creates HTML elements .cushion < .tile)
    var i = 0,
    list = sorted ? assorted : library,
    classes = ['tile'];    
    if(preview) classes.push('preview');
    for(var effect in list ){
      var animation = list[effect],
      background = dsb.fn.random.color(),
      title = $h( 'p', 'effect', animation ),
      tile = $h( 'div',{
        class: classes.join(' '),
        data_animation: animation, 
        style: { background: background }
      }, [title] ),
      cushion = $h( 'div', { class: 'cushion '
        + 'bg-'+( special(animation) || 'emerald')
      }, [ tile ] );      
      tiles.push( cushion );
      if(++i===list.length) tiles.push( $h('clear') );
    }//end for list[effect]
    
    //create and append element that contain tiles
    var box = $h('div','box', tiles );
    document.body.appendChild( box );
    
  } //end showcase()
  
/*********************************************************************/
}; //end dsb.animations
/*********************************************************************/
if( typeof module !== 'undefined' ) module.exports = dsb.animations;