module.exports = (function(DEBUG){
/******************************************************************************/

  /*
   * Scripting by Casey Dwayne. All rights reserved.
   * @file main.js
   * this is the (first, usually only) entry JS file.
   * it will be used to create the script.bundle upon 'build'
   */

/******************************************************************************/


var EDIT_CONTENT = false;
if( EDIT_CONTENT ){
  // CONTENTEDITABLE ON DOUBLECLICK
  var changes = false;
  var warning = null;
  var changes_warn = function(e){
    var msg = '<strong>Editing Mode</strong><br><br>'
      + 'Be sure to save any changes before exiting the page!';
    //msg += '<br>'+e.target+' '+e.target.innerHTML.slice(0,15)+'<br>';
    //prompt( msg );
    if( !changes ) {
      changes = true;
      var warner = document.createElement('div');
      warner.className = 'fixed bottom left z99 bg-red lite w100p';
      var warn = document.createElement('div');
      warn.innerHTML = msg;
      warn.className = 'center p1e';
      warner.appendChild( warn );
      document.body.appendChild(warner);
      warning = warner;
      window.addEventListener("beforeunload", function (e) {
      });
    }
  };
  var changed = null;
  $(document).on( 'dblclick', '*', function(e){
    changes_warn(e);
    changed = $(this);
    changed.attr( 'contenteditable', 'true' );
  }).on( 'keydown', function(e){
    if( e.which === 27 ){
      //console.log('pressed esc');
      if( !changes ) return;
      //console.log('clearing editable');
      $(this).find('*').attr( 'contenteditable', 'false' );
      warning.remove();
      changes = false;
      changed = warning = null;
    }
  });

}

/*----------------------------------------------------------------------------*/
}(0));
