module.exports = (function(DEBUG){
/*----------------------------------------------------------------------------*/

$(document).ready(function(){

  var carousels = $('.dsb-carousel');

  carousels.each(function(){

    var car = $(this);

    var prevBtn = car.find('[data-action="previous"]');

    var nextBtn = car.find('[data-action="next"]');

    var image = car.find('.image');

    var images = [];

    var currImgNum = -1;

    var makeImageEntry = function(text){

      return "https://via.placeholder.com/320x240/333/fff/?text=Placeholder " + text;

    }

    var fillImagesArray = function(num){

      var imgNum = 0;

      while(num--){

        imgNum++;

        var imgStr = makeImageEntry( imgNum );

        images.push( imgStr );

      }

    }

    fillImagesArray( 3 );

    var totalImages = images.length;

    var imagePressed = function(){

      console.log( 'User clicked image.' );

    }

    var prevPressed = function(){

      console.log( 'Previous button pressed.' );

      var atStart = ( currImgNum == 0 );

      if( atStart ) currImgNum = ( totalImages - 1 );

      else currImgNum--;

      changeImage();

    }

    var nextPressed = function(){

      console.log( 'Next button pressed.' );

      var atLimit = ( currImgNum == ( totalImages - 1 ) );

      if( atLimit ) currImgNum = 0;

      else currImgNum++;

      changeImage();

    }

    prevBtn.on( 'click', prevPressed );

    nextBtn.on( 'click', nextPressed );

    image.on( 'click', imagePressed );

    var changeImage = function( imgNum ){

      var supplied = typeof imgNum !== 'undefined';

      var nowImgNum = supplied ? imgNum : currImgNum;

      if( supplied ) currImgNum = imgNum;

      console.log( 'Changing to image number', nowImgNum )

      image.attr( 'src', images[nowImgNum] );

    }

    //initialize carousel
    console.log( images );
    changeImage( 0 );

  });

});

/*----------------------------------------------------------------------------*/
}(0));
