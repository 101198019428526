/*********************************************************************/
// for unit testing
/*********************************************************************/
if(typeof dsb === 'undefined') dsb = { log: function(m){ console.log(m) } };

/*********************************************************************/
// [ html functions ]
/*********************************************************************/

dsb.html = {

/*********************************************************************/
// [ create ] :: creates an HTML element and returns it.
/*********************************************************************/
    // case 1: simple.  create('div')
    // case 2: properties.  create('div',{class:'myClassName'})
    // case 3: inner.  create('div',{class:'myClassName'},'some <u>html</u> for inside new element') ( {} optional )
    // case 4: tag,class,inner.  create( 'div', 'myClassName', 'some <u>html</u> for inside new element' ) ( class optional )
    // case 5: merge inner.  create('div',{ class:'myClassName', inner: 'some <u>html</u> for inside new element' })
    // case 6: merge all.  create({ element: 'div', class': 'myClassName', inner: 'some <u>html</u> for inside new element' })
    // inner also accepts objects via true array [ obj1, obj2 ], will append
    
    
  create: function(element,properties,inner){
    
    var object, options = {}, self = this;

    //handle special elements
    if( element === 'clear' ) return dsb.html.create('div','clear','');
    
    // [ element ]

    //cases 1-5
    if( dsb.fn.isString( element ) ) options.element = element; 

    //case 6    
    if( dsb.fn.isObjectArray( element ) ) options = element;          

    //create new DOM object
    object = document.createElement(options.element);
    

    // [ properties ]    
    
     //cases 2+
    
    var properties = properties || options.properies;

    if(properties){
          
      //cases 2 3 5
      if( dsb.fn.isObjectArray( properties ) ){

        
        object = dsb.html.property( object, properties );

        // case 5 ( properties { inner:'' } exists )
        if(properties.inner) options.inner = properties.inner;
        
      }

      else {
        
        //case 4        
        if( dsb.fn.isString( properties ) ){ 
          // if no inner, properties is passed as inner - if properties is a string && inner, it becomes the elements class 
          if( inner || inner=='' ) { 
            options.properties = { class: properties }
            object = dsb.html.property( object, options.properties );
            options.inner = inner; //optional line, caught by 'inner' checks
          }
          else options.inner = properties;          
        }        
        
      }
      
    }     
    
    // [ inner ]

    if( options.inner || inner ){
      
      //cases 3 & 6
      if( !options.inner ) options.inner = inner;
      
      //cases 2+
      if( dsb.fn.isArray( options.inner ) ){
        var a = options.inner;
        for(var i in a) object.appendChild( a[i] );
      }
      else {
        object.innerHTML = options.inner || 'no html found...';
      }
    }
    
    if( false ) {
      
      var s = function(o){
        var cache = [];
        JSON.stringify(o, function(key, value) {
          if (typeof value === 'object' && value !== null) {
            if (cache.indexOf(value) !== -1) {
              // Circular reference found, discard key
              return;
            }
            // Store value in our collection
            cache.push(value);
          }
          return value;
        });
        cache = null; // Enable garbage collection
      };
      
      var info = 'dsb.html: ['+options.element+'] created with '+JSON.stringify(options.properties)+'. Inner: '+options.inner;    
      dsb.log(info,15,1);
    
    }
    
    return object;
      
  },
  
/*********************************************************************/
// [ property ] :: adds a single or multple {} properties to an object  
/*********************************************************************/  
  //case 1:  property( myHTML_Object, class, 'myClassName')
  //case 2:  property( myHTML_Object, { class: 'myClassName', disabled: true })  
  property: function(object, prop, value){
    var self = this, a = dsb.fn.isObjectArray, convert = function(o){ return o.replace(/_/g, '-'); }
    if( a( prop ) ){
      for(var i in prop) self.property ( object, i, prop[i] )
    } else {      
      
      if( a(value) ){  // handle object_array, currently .css style only ( {style: properties} )
        var v = '';
        for(var i in value){ v += convert( i )+':'+value[i]+'; '; }
        value = v
      }
      
      prop = convert( prop );
      object.setAttribute(prop,value);
      dsb.log('Set '+prop+' to '+value,15,1);
    }
    return object;
/*
    if( dsb.fn.isObjectArray( object ) ){
      for(var i in object) this.set ( i, object[i] )
    } else object.setAttribute(prop,value);
  //  document.
*/  
  },
  
  map: function(array){
  },

  resolve: function(){
    alert( arguments );
  },

  clear: function(){
    return this.create('div','clear','');
  },

/*********************************************************************/
// [ form ] :: easily create a complete form
/*********************************************************************/  

  form: {

    create: function(form,fields){
      dsb.log('creating form from '+fields.length+' fields');
      var f = document.createElement('form'),
      ff = this.fields;
      ff = fields;
      //$(f).prop(form);
      
      //apply form properties 
//      alert( dsb.fn.isArray(fields) );
      dsb.html.property(f,form);
      
//      alert(fields.toString());
      
      for(var i in ff){
        f.appendChild( ff[i] );
      }

      $('body').append(f)

    },
    
    count: 0,
    fields: {},

    label: function(name,target){
      var l = document.createElement('label');
      l.text = name;
      l.target = target;
      return l;
    },

    register: function( field ){
      this.fields[this.count] = field;
      this.count++;
    },

  // inputs

    inputs: 0,

    input: function(type,properties){
      var output,
      i = document.createElement('input'),
      p = {};
      //resolve type/properties
      i.type = 'text';
      if( dsb.fn.isObjectArray(type) ) p = type;
      else i.type = type;      

      if(properties){
        p = properties;
        if(!p.name) p.name = 'input-'+this.count;
        if(p.label){ 
          var l = this.label( p.label, p.name ),
          s = dsb.html.create('span');
          s.appendChild(l);
          s.appendChild(i);
          p.wrapper = s;
          output = s;          
        }
        else output = i;
      } else output = i;
      
      p.type = i.type;
      
      dsb.html.property( i, p );
      
      this.register( p )
      
      return output;
      
    },

    // type modifiers
    radios: [],
    radio: function(properties){
      var r = this.input( 'radio', properties );
      this.radios.push(r);
      return r;
    },
    
    // textarea
    textareas: 0,
    textarea: function(properties){
//      var t = document.createElement('textarea');
      var t = 'textarea',
      p = properties ? properties : {},
      o = dsb.html.create(t,p);
      
      this.textareas++;
      //this.set(t,properties);
      this.register( { type: t, object: o } );
//      return $(t).prop(properties);      
      return(o);
    },

    // booleans
    booleans: 0,
    boolean: function(y,n){
      this.booleans++;
      var yes = { label: 'true', name: 'boolean-'+this.booleans };
      this.booleans++;      
      var no = { label: 'false', name: 'boolean-'+this.booleans };
      
      var o = dsb.html.create('span'),      
      y = this.radio( yes ), n = this.radio( no );
      y = dsb.html.create('div');
      y.text = 'abc';
      o.appendChild (  y );
//      o.append( n );
  //    o.appendChild( this.radio( no ) );            
//$('body').append( o ) 
//      dsb.html.form.boolean('nae  }
//      this.radio(
      return o;
    }
  },
  
  
  
  // [ t ] :: d
  // [ t ] :: d
  // [ t ] :: d      


  test: function(){ alert( 'html working' ) },

/*********************************************************************/

//////////
} //end of dsb.html{}
////////////
/*********************************************************************/


/*********************************************************************

var i = dsb.html.form.input(  'text', { title:'Testing', value: 'Yay!', type: 'radio' }  );
$('body').append(i);
//alert( );
/*********************************************************************


var t = function(a,b,c){
  var s = [].slice.apply(arguments);
  var o = s.join(' and ');
  dsb.log( o + ' found '+ arguments.length)
  for( var i in s ) dsb.log(s+' = '+s[i]+' :::: '+arguments[i]);
}
t({a:1},'string',[1,2,3]);
//dsb.html.resolve(t);


/*********************************************************************/
////\\\\\\\\\\\\\\
// [ Test Zone ] ||
//|||||||||||||||||
/*********************************************************************


var f = dsb.html.form;

t = document.createElement('div');
t.text = 'divtest';

var t1 = f.input( { label: 'test1', } );
var t2 = f.input( { label: 'test2', } )

//$('body').append( );

f.create( 
  { name: 'testform' }, 
  [ t1, t2, f.textarea() 
//    f.textarea(),
//    t,
//    f.boolean('yup','nah') 
  ] 
);

/*********************************************************************/  

if( typeof module !== 'undefined' ) module.exports = dsb.html;