$(document).ready(function(){

  //form input controls

  $(document).on( 'keyup', 'input, textarea', function(){
    var x = $(this),
        c = 'has-value';
    if( x.val() !== '' ) x.addClass( c )
    else x.removeClass( c );
    //console.log('running has value fn')
  });


  //loader functionality

  (function(){

    var logo_hidden = false;

    var al = $('.animated-logo'),
       alf = $('.animated-logo-fallback'),
    modern = $('html').hasClass('svgasimg csstransforms');

    var swap_loaders = function(){
      if( modern ){
        //al.css({'display':'none'});
        al.remove();
        alf.css({'display':'block'});
      }
    };

    var after_hide = function(){
      logo_hidden = true;
      //swap_loaders();
    };
    var after_show = function(){
      logo_hidden = false;
    };

    var l = '.loader',
        s = function(n){
          if( logo_hidden )
            $(l).fadeIn(n||400, after_show);
        },
        h = function(){
          if( !logo_hidden )
            $(l).fadeOut(400, after_hide);
        },
        q = function(){
          if( !logo_hidden )
            $(l).fadeOut(300, after_hide);
        },
        f = function(_time){
          setTimeout( h, _time || 1800 );
        },
        a = function(){
          f(); s(500);
        };

    //quickly hide on dblclick
    $('body').on( 'dblclick', l, q );

    /*
    var _a = function(){
      if( modern ){
        al.hide();
        alf.show();
      }
      a();
    };
    var _h = function(){
      if( modern ){
        al.show();
        alf.hide();
      }
      h();
    };
  */
    if( !false ){
      if( window.onpageshow ){
        // window.onpagehide && window.onpagehide = a; // THIS IS DISABLED SINCE IT'S NO LONGER SITE-WIDE
        //window.onpageshow = h;
      }
      window.onload = a; //for older browsers
    }

    //show when non-immune link is clicked
    $('body').on( 'click', 'a', function(e){
      var t = e.target,
          r = t.getAttribute('href'),
          n = t.getAttribute('name');
      //@todo add .immune detection, probably
      if( n || ( r && r.indexOf('#') > -1 ) ) return;
      else a();
    });

    f(5000); //clear loader after 5 seconds regardless [prevent "forever" error]

  }());

  //custom logo animation (DISABLED)

  (function(){

    //custom code for animation
    var _al = $('.animated-logo'),
      _alh = _al.html();

    var reset_animation = function(){
      _al.fadeOut( 500, function(){
        _al.empty().html(_alh).fadeIn();
      });
    };

    var loop = null;

    var repeat_animation = function(){
      //console.log('repeating animation');
      if( loop ) reset_animation();
      loop = setTimeout( repeat_animation, 3400 );//3400
    };

    var clear_animation = function(){
      clearInterval(loop);
    };

    //var start_animation
    var stop_animation = function(){
      clearInterval(loop);
    };

    repeat_animation();
    setTimeout( stop_animation, 5000 ); //stop animation after 5 seconds

  }); //LEAVE DISABLED (ALEX REQUEST)

  /*
  (function(){

    var arrow_container = $( document.createElement('div') );
    arrow_container.css({
      'position': 'fixed',
      'bottom': '13px',
      'width': '100%',
      'height': '7px',
      'background': 'blue',
      'z-index': 120
    });
    arrow_container.html('<img src="https://alexsellsrichmond.com/files/media/assets/down-arrow.svg" />');

    $('body').append( arrow_container );

    $(window).on('scroll',function(e){
      var y = $(window).scrollTop();
      if( !y ) arrow_container.show();
      else arrow_container.hide();
    })

  });
  */
});
