(function(){
  
  var toArray = function(){ var a = [], o = o || this; if( o instanceof Array ) return o; for(var i in o) a.push(o[i]); return a; };
  
  /*
   * @method Logger
   * @constructor
   * @requires jQuery
   */
  
  var logger = function Logger(settings){ 

    var _logger = {            
              
      /* @property active
       * active state of logger
       * @type boolean
       */
      
      active: false,
      
      /* @property _setup
       * has setup() been called
       * @type boolean
       */
      
      _setup: false,
      
      /* @property box
       * the container object the log enters entries into
       * @type DOMObject
       */
      
      box: document.createElement('div'),
      
      /* @property queue
       * @type object
       */
      
      queue: require('./queue').create(),
            
      /* @property count
       * total entries entered into logger
       * @type number
       */
      
      count: 0,
      
      /* @property loop
       * stores the timeout loop for autohide
       * @type setTimeout
       */
      
      loop: null,
            
      /* @property method
       * active state of logger
       * @type boolean
       */
      
      muted: false, //silences output
      
      /* @method muted
       * mutes further output from the logger
       */
      
      mute: function(){ this.muted = true;  },
      
      /* @method unmute
       * unmutes the logger
       */
      
      unmute: function(){ this.muted = false; },
      
      /* @method test
       * test if logger is properly linked
       */
      
      test: function(m){ alert(m || 'Fired from Logger'); },

      /*
       * @property options {Object}
       * the default options for the logger
       * @type object
       */

      options: {
              
        /* @property showgroup
         * @type boolean
         */      
        
        showgroup: false,
        
        /* @property css
         * the default CSS for the box
         * @type object
         */
        
        css: {
          'position'      :  'fixed',
          'right'         :  '.5%',
          'bottom'        :  '5%',
          'background'    :  '#FFF',
          'border'        :  '3px double blue',
          'color'         :  '#000',
          'padding'       :  '1%',
          'border-radius' :  '5px',
          'width'         :  '100%',
          'max-width'     :  '480px',
          'opacity'       :  .5,
          'z-index'       :  200,
          'cursor'        :  'default',
          'font-size'     :  '1.4em',
          'overflow'      :  'scroll',
          'height'        :  '50%',
          'max-height'    :  '400px'
        }, 
        
        /* @property autohide
         * automatically hide the box after [delay]
         * @type boolean
         */      
        
        autohide: false,
        
        /* @property delay
         * if autohide, hide box if no entry for [delay] ms
         * @type number (in milliseconds)
         */      
        
        delay: 5000,
        
        /* @property reversed
         * if reversed, log inserts newer entries to the top instead of bottom
         * @type boolean
         */      
        
        reversed: true,
        
        /* @property style
         * allow CSS styles on logger entries
         * @type boolean
         */      
        
        style: true,
        
        /* @property default
         * apply options.css to box
         * @type boolean
         */
        
        defaultStyles: true,
          
        /* @property target
         * target to attach logger box to
         * @type DOMObject
         */
        
        target: null,
        
       /* @property selector
        * classname for log container
        * @type string
        */
      
        selector: 'log-box',
            
        /* @property level
         * default priority level (hides outpouts with priority greater than level)
         * @type number
         */
      
        level: 5, 
      
        /* @property limit
         * truncates entries greater than limit
         * @type number
         */
      
        limit: 100
        
      },

      
      /* @method _attach 
       * @private
       * attaches box to target
       */
     
      _attach: function(){

        var l = this, o = l.options, s = o.selector, b = l.box, t = o.target || document.getElementsByTagName('body')[0],
        a = function(){ t.appendChild( b ); };
        b.className = s;
        if( t ) a();
        else window.onload = a;
        
        l.box = $(l.box);
        l.style();
                
        
        if( o.autohide ) l.box.style.display = 'none';        
        
      },

      /* @method setup
       * sets up the intital
       * @param {object} settings
       * @returns {}
       */
      
      setup: function(settings){        
        
        var l = this, s = l.options = settings ? l.options.merge(settings) : l.options,
                
        start = function(){
                    
          l._attach(); //attach for 1st time
          l.active = true;
          l.queue.process(function(v){
            var a = v.toArray();
            l.report.apply(null,a);
            //alert('processing '+a.first)
          });
          //alert( s.defaultStyles )
          
          
        };        
        
        if( !l.active && !s.disable ){
          if( !l._setup ){
            window.onload = start;
            l._setup = true;
          }
        }        
        
      },
       
      /* @method _log
       * @private
       * standard console log
       * @param {DOM Element} target
       * @return {Object} newly created logger object
       */

      _log: function(m){          
        
        if( console &&  window.console ){           
          var a = toArray(arguments);
          if( console.log.apply ) console.log.apply(window, a.length ? a : ['nothing to log...'] );
          else console.log(a);
        }
        
      },

      /* @method report
       * creates a log entry
       * @param {type} message
       * @param {type} priority
       * @param {type} title
       * @param {type} group
       * @param {type} extra
       * @return nothing
       * - case 1: simple.  report('message')
       * - case 2: priority.  report('message',5); hides if logger.level<priority
       * - case 3: titled.  report('message',(5),'My') prints My: message
       * - case 4: grouped.  report('message',5,('My'), 6) assigns to group. title optional
       * - case 5: extra.  report('message',(5),'My title',(6),':: extra') prints message:: extra
       * - NEW: case 6: console only.  report('message',true)  
       */

        
      report: function(m,p,t,g,e){  // [m]essage, [p]riority, [t]itle, [g]roup, [e]xtra (at the end)
                             
        var l = _logger;
        
        if( !l.active ){
          l.setup(); //force setup() if report() called
          l.queue.add( arguments );
          return false;
        }        

        if( l.muted ) return false; //muted loggers tell no tales

        // handle multiples (js array [{m},{m}] ? )

        if( Array.isArray(m) ){
          for (var a in m) l.report( m[a] );
          return false;  
        }

        // resolve arguments

        if(p){ //check for priority #
          if(typeof p === 'string') t = p; 
          if(t){
            if(typeof t === 'string') m = t+': '+m;  // title, add to beginning
            if(typeof t === 'number') g = t;
            // [ ]
            if(g){ //group detected
              if(typeof g !== 'number') e = g; //pass as extra
              else {
                var gt = ( g in l.groups ) ? g+':'+l.groups[g] : 'Group '+g;
                if(l.options.showgroup) m = '['+gt+'] '+m; 
              }
            }
            if(e) m += ' '+e;  // extra, add to end
          }
        }

      // setup, output, and loop if needed

        var options = l.options,
          a = options.autohide,    
          b = $(l.box),
          i = l.count,
          limit = l.limit,
          d = options.delay;
          //style = new RegExp(/\s*?\%(\S+):\s*?/,'gi'), //original
          //style = new RegExp(/\s?:(.*)\%(\S+):\s?:(.*)/,'gi'),
          //fancy = m.match(style) || false;

          //if( fancy ) m = m.replace(style,''); //strip styles from output

          var o = m, //store original message
          m = '<p> ['+i+'] '+o+'</p>',
          p = typeof(p)!=='number' ? 5 : p,
          r = function(){ b.fadeOut( function(){ b.empty(); } ); l.count = 0; },      // reset log
          s = function(){ if(a) l.loop = setInterval(r,d); },                         // set loop
          c = function(){ if(typeof(l.loop)!=='undefined') clearInterval(l.loop); }; // clear loop

          if(p>l.level) return false;  //kill if non-priority  




      //check for %any.styles: and strip from output;




      // visibility & final output

          var f = b.is(':visible')
          ? function(){
            options.reversed ? b.append(m) : b.prepend(m);
            s();
          }
          : function(){ 
            options.reversed ? b.append(m) : b.prepend(m);
            b.fadeIn(500);    
            s();
          };
          f();

        c(); //clear timer for additional mesages

        if(a) b.on('mouseenter',c).on('mouseleave',s);  

        // truncate old messages  
        if(b.children().length>limit) ( options.reversed ?  function(){ b.children().first().remove(); }() : function(){ b.children().last().remove(); }() );

        //demo mode
        var demo = true, shown = false;
        // output original message to classic console
        if( l.classic && console ){
          l.fancyConsole('[JS]: '+o);    
        }


        l.count++;  

      }, //end .report()

      /* @method fancyConsole
       * converts %any.style.chain: to stylized console output       
       */

      fancyConsole: function(i){    
        return i;    
      },

      /* @method toggle 
       * toggle box visibility  
       */

      toggle: function(){ this.log.box.toggle(); },

      /* @method style
       * determine and apply styles
       */

      style: function(options){
        var l = this, options = options || l.options,
        s = options.style, d = options.defaultStyles;      
        if( s ){
          if(d) l.box.css( l.options.css );
          else l.box.removeAttr('style');
          l.box.css( options.css );
        }      
      },      
      create: function(options){
        return new logger(options);
      }
    }; //end _logger
    
    return _logger;
    
  };//end logger
  
  /* @method create
   * creates a new logger
   * @returns {object} new logger   
   */

  logger.create = function(options){    
    return new logger(options);
  };
  
  if( typeof module !== 'undefined' ) module.exports = logger;

}());