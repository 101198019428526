module.exports = (function(DEBUG){
/******************************************************************************/

  /*
   * Scripting by Casey Dwayne. All rights reserved.
   * @file main.js
   * this is the (first, usually only) entry JS file.
   * it will be used to create the script.bundle upon 'build'
   */

/******************************************************************************/

  var eq = function( group, target ){
    var group = group ? $('body').find( group ) : $( document ),
       target = target || '.eqh',
      targets = $(group).find(target),
          max = 0;
    if( !group.length ) return;
    targets.each(function(){
      //var h = $(this).outerHeight(true);
      var x = $(this);
      var h = x.height();
      if( h > max ) max = h;
    });
    //console.log( 'setting to', max );
    targets.css( 'height', max );
  };

  //automatically equalize .eq-group(s)
  var setEqs = function(){
    eq( '.eq-group', '.eqh' );
    eq( document, '.eqh' );
  };

  setTimeout( function(){
    setEqs();
    $(window).on( 'resize', setEqs );
  }, 2000 );

  return eq;

/*----------------------------------------------------------------------------*/
}(0));
