(function(){

  /* @method queue
   * @constructor
   * @param {Function} callback
   */
  
  var queue = function Queue(callback){
    
    var q = this;
    
    q.list = [];    
    
    q.add = function(item){      
      var c = q.list.push(item);
      q.debug('adding item %d, typeof: %w',c,typeof item);
    };
    
    q.remove = function(i){
      delete q.list[i];
    };
    
    q.clear = function(){
      this.list = [];  
    };
        
    q.clone = function(){
      return q.list;
    };
    
    q.count = function(){
      return q.list.length;
    };
    
    q.process = function(method){      
      q.list.each(function(item,index){
        q.debug('processing item %d of %d',parseInt(index)+1,q.count());
        method(item);
      });
    };
    
    q.debug_mode = false;
    q.debug = function(m){
      if( console && q.debug_mode ){
        var a = arguments.toArray();
        a[0] = 'Queue debug: '+a[0];
        console.log.apply(null,a);
      }
    };  
  };//end queue|Queue
  
  queue.create = function(){
      //queue.debug('newq');
      return new queue;  
    };  
    
  if( module ) module.exports = queue;
  //if( define ) define(function(){ return new queue; });
  return queue;
  
}());