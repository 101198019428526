module.exports = (function(DEBUG){
/******************************************************************************/

  /*
   * Scripting by Casey Dwayne. All rights reserved.
   * @file main.js
   * this is the (first, usually only) entry JS file.
   * it will be used to create the script.bundle upon 'build'
   */

/******************************************************************************/

  if( DEBUG > 2 ) console.log('!!! start of [ main.js ]');

  //@note moved to a manual script tag
  //add jQuery
  //require( './jquery.js' );

  var htdocs = '../../../../';
  var path = require('path');
  var dsb_address = 'dsb-old';
  var dsb = path.join( __dirname, htdocs, dsb_address + '/js' );
  var paths = {
    _dsb: path.join( __dirname, htdocs, '_dsb/node_modules/' ),
     dsb: dsb
  };

  //window.Modernizr = require('./shims/modernizr.custom');
   var modernizr = window.modernizr = require('./shims/modernizr-custom-2');

  //import DSB
  var dsb = window.dsb = require( '../../../../dsb-old/js' );

  //header hack for big screens
  var fixHeader = (function(){
    var _ww = $(window).width();
    var _h = $('header');
    var _hw = _h.width();
    if( _ww > 1920 ){
      var left = ( _ww - _hw ) / 2;
      // alert( left );
      _h.css( 'left', left );
      // _h.css( 'left', left );
    }
  });

  setTimeout( fixHeader, 50 );
  $(window).on( 'resize', fixHeader );
  // alert('here');
  var bigScreenActivated = false;
  var resetHeaderAdjustment = function(){
    var __h = $('#header');
    __h.css({
      'top': '0px',
    });
    bigScreenActivated = false;
  }
  var adjustHeader = function(){
    //only adjust for big screens
    var _ww = $(window).width();
    if( _ww <= 1920 ){
      if( bigScreenActivated ) resetHeaderAdjustment();
      return;
    }
    var _h = $('header');
    var __h = $('#header');
    var _hh = _h.height();
    // var _hh = _h.outerHeight(false);
    var _hh = __h.outerHeight(true);
    var _y = window.scrollY;
    var offset = ( Math.round( _hh ) + 4 );
    __h.css({ 'transition': 'all 0.04s linear' });
    if( _y >= ( _hh - 4 ) ){
      if( !bigScreenActivated ) bigScreenActivated = true;
      // _h.css( 'top', 0 )
      // return;
      __h.css({
        // 'position': 'absolute',
        // 'top': ( _y - _hh ) + 'px',
        'top': -offset  + 'px',
      });
      // console.log( 'header offset', -offset );
    } else {
      resetHeaderAdjustment();
    }

  };

  // $(window).on( 'load', function(){ adjustHeader(); console.log('loading'); });
  $(window).on( 'load scroll resize', adjustHeader );

  //import gatekeeper system
  //var gk = window.gatekeeper = require( '../../../../_dsb/node_modules/dsb-gatekeeper/index.js' );
  //var gk = window.gatekeeper = function(){};

  //dsb.traveler = require('./traveler');

  if( DEBUG > 2 ) console.log('!!! end of [ main.js ]');
  require('./monster.js');

  // first add raf shim
  // http://www.paulirish.com/2011/requestanimationframe-for-smart-animating/
  window.requestAnimFrame = (function(){
    return  window.requestAnimationFrame       ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame    ||
      function( callback ){
        window.setTimeout(callback, 1000 / 60);
      };
  })();

  var eq = require('./eq');

  var run_processes = function(){

    //@PROCESS: make block footers equal and centered
    $('.block').find('footer').each(function(x){
      //console.log($(this).text());
    });
    $('.block-group').each(function(){
      var x = $(this);
      x.find('footer').addClass('flex');
      eq( x.find('.block')  , 'footer' );
    });

    //@PROCESS: collapse expandee, toggle on click
    var expander = $('body').find('.expander');
    expander.each(function(){
      var x = $(this);
      var n = x.next(); //next element
      var h = 'hide';
      n.addClass( h )
      var s = false;
      x.on( 'click', function(){
        n.toggleClass(h);
        s = !s;
        if( s ) scrollToFragment( x );
        //if( t.hasClass(h) ) .addClass()
      });

    })

  };

  $(document).ready(run_processes);
  $(document).on( 'resize', run_processes );

  require('./edit');
  require('./scroll');
  require('./content');
  require('./carousel');
  dsb.forms = require('./form');

/******************************************************************************/

  return dsb;

/*----------------------------------------------------------------------------*/
}(0));
