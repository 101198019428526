(function(){
/**
The prototype module provides a powerful set of methods that can extend and be extended on constructors or native JS objects.
@module Prototype

*/
/** [ Master ]
* Master is the base class for prototype extensions
* @class Master
* @constructor
* @param {Object} options
* @return {Object} Master
* @protected
 */

var Master = function Master(options){
  var m = master = master || this;
  master.handle = options && options.handle && ( typeof options.handle === 'string' ) ? options.handle : false;
  master.object = Object,
  master.function = Function,
  master.number = Number,
  master.array = Array,
  master.string = String,
  master.boolean = Boolean,
  master.RX = { //A compiled-once-at-runtime collection regular expressions
    'objectType': /\[\w+ (.*)\]/ //returns the true type of a JS object ( [ Object TrueObjectType ] )
  },
  master.typecheck = function(c){ // advanced type-checking made easy
    var t = Object.prototype.toString.call( this ),
    m = t.match(master.RX.objectType),
    r = m ? m[1].toLowerCase() : t;  
    return c ? r.indexOf( c.toLowerCase() )>-1 : r;
  },
  master.defined = function(v){ return( typeof v !== 'undefined' ); },
  master.fire = function( fn, args, context ){
    context = context || null, args = args || [];
    if( fn && fn.is('function') ){
      return fn.apply( context, args );  
    }
    return false; //returns result of function
  },
  master.callback = function(fn,params,context){
    var d = m.defined, t = m.typecheck;
    if( d(fn) ){
      if( t.call(fn,'function') ){
        if( d( params ) ){
          if( t.call(d,'array') ){}        
       }
      }
      else {
      }
    }  else return false;
  },
  master.log = function(){ 
    if(console && window.console){ 
      var a = arguments.toArray(); 
      console.log.apply(window, a.length ? a : ['nothing to log...'] ); 
   }
   else {
     var defaults = ['assert', 'count', 'dir', 'dirxml', 'error', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log', 'profile', 'profileEnd', 'table', 'time', 'timeEnd', 'timeStamp', 'trace', 'warn'],
     fn = function(){};
     window.console = {};
     for(var l in defaults) window.console[ defaults[l] ] = fn;
   }
  },
/*  
  // [ fire ] :: fires a function ('this') with arguments ( params ). if only 1 arg and is array, will be applied. context neutral.
  master.fire= function(){
    var fn = this, args = arguments.toArray(), context = args.shift(), params = args, length = params.length, result = null,
    method = ( fn && fn.is('function') )  ? fn : function(v){ return v; },    
    result = length ? method.apply( context, params ) : method.call( context );
    alert(result)
    return result;
  }
(/  
*/
  master.version = '1.0a';
  return master;
};//end of Master constructor

var master = new Master({ handle: '_proto_' });
// [ Teacher/Student prototype modification ]

// [ teach ] :: adds a prototype property to student ('this'), or to student itself if no prototype (non-constructor)
master.object.defineProperty( master.object.prototype, 'teach', { 
  value: function( name, property, brainwash ){ //brainwash sets a function 
    var student = this,
    brain = student.prototype || student,
    lesson = function(_name,_property){
      var teacher = false;
      if( _property !== null && typeof _property === 'object' && _property.hasOwnProperty('teacher') )  teacher = true;
      if(!teacher) _property = (brainwash) ? { value: _property } : { configurable: true, writable: true, value: _property };
      Object.defineProperty( brain, _name, _property );
    }; //end lesson()
    if( typeof name === 'string' ) lesson( name, property );
    if( typeof name === 'object' ) for(var i in name) lesson( i, name[i] );
    return student;
  }
});

// [ learn ] :: extends student(this) prototype in a non-destructive way (unless brainwashed/override:true)
master.object.defineProperty( master.object.prototype, 'learn', { 
  value: function( teacher, brainwash ){
    var student = this, knowledge, commit;
    try{ knowledge = new teacher; } catch(e){ knowledge = teacher; } //Object.create( teacher.prototype )    
    commit = function(lesson){ student.teach( lesson, knowledge[lesson] ); };
    for( var lesson in knowledge ) ( lesson in student.prototype ) ? (brainwash ? commit(lesson) : null) : commit(lesson);
    return student;  
  }
});

// [ dsb cheats to delete ]
//alert('log'+log)
if(typeof window !== 'undefined'){
  if( master.handle ) window.teach( master.handle, master );  
  window.teach({  
  //  'log':  function(m){ console.log( m || 'nothing to log...'); },  
    'fn': function(){ var a = arguments.toArray(), m = a.shift(); return m.bind(a); },
    //$fn
  //  '$': master,
    '$$':  {  teacher: true,  set: function(m){ alert('$$ = '+ m ); }  },  
    '$$$':  {  teacher: true,  set: function(o){ $$ = o ? o.printr : 'object is undefined, printr was aborted.'; }  }
  });
}

master.object.teach('extend', function(v){
      var t = typeof v;
      if(t === 'object'){
        for(var i in v){ //value object
          var value = v[i];
//          console.log('EXTENDING:: '+i+'. Found: '+value)
          if( this[i] ){ //check if object key links to real property of 'this'          
            var x = this[i], xt = typeof x;
            if( xt === 'function' ) x.apply(this,value);
            else if( typeof value === typeof x ) this[i] = value;
          }//if this[i]
        }//for
      }//if obj
});


// [ Object ]

master.object.teach({
  // [ typeof ] : lazy typechecking. returns true [ Object (type) ] type. will compare with any string passed (ie. document.createElement('canvas').typeof('canvas') returns true!
  'typeof': master.typecheck,
  // [ is ] : advanced typechecking.
  'is': function(v){
    var s = this, r = null;
    r = ( s === v ) ? true : s.typeof(v);
    return r;
  },
  // [ each ] : works on objects || arrays. passes thru the function (1st arg) supplying (value, key, index). returns output of function or original value if no function output.
  'each': function(){ var index = 0, args = arguments.toArray(), fn = args.shift(); for(var i in this) if (typeof fn === 'function') this[ i ] = fn( this[ i ], i, index++, args ) || this[ i ]; return this; },
//  'then': function(fn){ console.log('executing then'); var x = this; return ( fn.typeof('function') ) ? fn( x ) : x;   },
  // [ construct ] : creates a new object from object || array. keys represent (key): value pair in new object, initial is optional default value for non-supplied presets
  //*Any existing keys will have value overwritten unless defined in presets! Presets must be an array ( i.e., [ 'bar' ].construct('foo double0 wonders',7) => { foo: 'bar', double0: 7, wonders: 7 } )
  'construct': function(keys,initial){ 
    var object = this, presets = false, map = keys, _default = typeof initial !== 'undefined' ? initial :   null;
    if( typeof object !== 'object' ) throw new Error('Cannot construct on non-object');
    if( object instanceof Array ) {
      presets = object; //stores array to later place values in object under the appropriate key
      object = new Object();
    }
    if( map.typeof('string') ) { map = map.chop(); }
    if( map instanceof Array ){      
      for(var i in map) object[ map[i] ] = typeof presets[i] !== 'undefined' ? presets[i] : typeof _default === 'function' ? _default( map[i], i ) : _default;      
      return object;
    } else throw new Error('construct needs a string separated by spaces &| commas, OR a true array for the (map) argument');
  },

  // [ deploy ] : looks for property in parent object (this). if there, a) function, value must be array || b) non-function, must match parent[property]: value TYPE
  //really need to move this out of Object.prototype.. Baby.prototype would be just fine...
  'deploy': function(object){ //v must be a true js object
    var self = this; //parent object to deploy against
      if( object.typeof('object') ){
        //loop through object being deployed
        for(var property in object){ //value object 
          var value = object[property];
            //check if parent property exists and is a function
            if( self[property] && self[property].typeof('function') ){ //execute fn with parent as 'this'
              if ( value.typeof('array') ) self[property].apply(this,value); //multiple args via array
              else self[property].call(this,value);  //single argument via value
            }
            //non-function properties are simply overwritten
            else {
              if( property.contains('.') ) property = self.stack( property, value );
              else self[property] = value;
            }
          }//end deploy loop
      }//end if object      
      return self;
    },
  
  // [ merge ] : combines 2 or more objects. automatically overwriting and recursive, typechecking off by default.
  //2nd use added: string + value = object[string] = value
  'merge': function(){ // ( -overwrite, -deep, -typecheck, merger1, m2, ..., mN )
    var original = this, mergers = [], args = arguments.toArray(), f = args.first, s = args.second, overwrite, deep;
    if( f && f.is('string') && typeof s !== 'undefined' ){
      original[ f ] = s;
      return original;
    }
    var merge = function( o1, o2 ){
//      console.log('found '+o2.printr+' to merge');
      for(var i in o2){
        if( o1[i] ){ //found existing - conflict resolution
          if( overwrite ){
            if( o1[i].typeof('object') ){ 
              if( o2[i].typeof('object') && deep ) merge( o1[i], o2[i] );
              else {
                if( typecheck ){
                  if( o1[i].typeof() === o2[i].typeof() ) o1[i] = o2[i];
                } else o1[i] = o2[i];
              }
            } else o1[i] = o2[i]; //non-objects
          } else return; //do nothing if overwrite false
        } else o1[i] = o2[i]; //no conflict, add mi to oi
      }//end for loop
    };//end merge()
    //resolve arguments
    overwrite = args[  0] && args[0].typeof('boolean') && false ? ( false, args.shift() ) : true;
    deep = args[1] && args[1].typeof('boolean') && false ? ( args.shift(), false ) : true;
    typecheck = args[2] && args[2].typeof('boolean') && true ? ( args.shift(), true ) : false;
    mergers = args;
    //merge and return
    for( var n in mergers ) merge( original, mergers[n] );
    return original; //modified original
  },
  // [ map ] : creates a new object based on old. processes all values of new object through function
  'map': function(fn){
    var o = this, n = {};
    if(typeof fn === 'function') for( var i in o ) n[i] =fn(o[i],i);
    return n;
  },
  // [ stack ] : accepts an array or concated.string, attach real properties it to parent object (this). if value is set, it sets instead of gets.
  // *( prefix ) : if set, it will prepend the last property in the chain (prefix: '_' = _myprop). useful for creating private backups of objects. only works if value is set.
  'stack': function( chain, value, prefix ){
//    alert(chain.typeof('string'))
    if( chain.typeof('string')  ) chain = chain.chop('.');
    if( !(chain.typeof('array')) || !chain.length ) throw new Error('the stack chain must be an array or a "valid.string.chain"');
    var _stack = parent = this, overflow = false, stack = function( _stack, _chain ){
      var __stack = _stack;
      for(var _link in _chain) if( _chain[_link] in __stack ) __stack = __stack[ _chain[_link] ];
      return __stack;
    };
    //changes value only if value is set
    if( value ){
      if( chain.length>1 ){
        overflow = chain.pop();        
        _stack = _stack.stack( chain );          
      }
      var _overflow = ( overflow ? overflow : chain.toString() ),
      overflow = ( prefix ? prefix+_overflow : _overflow ),
      stack = _stack[ overflow ];
      _stack[ overflow ] = value;
      return stack;
    }
    //otherwise, return the stacked object (= parent[ stacked ][ properties ] )
    else return stack(parent, chain);
  },
  // [ printr ] : prints a shallow printout of any object
  'printr': { teacher: true, get: function(){ var o = this, r = '', b = '\r\n', s = '\t'; for( var i in o ) r+= s+i+': '+o[i]+b; return('{ '+b+r+b+'}, '); } },
  'stringit': { teacher:true, get: function(){ return this.toString(); }  },
  // [ stringer ] : accepts a string representing property chain of parent object (i.e., myObject.stringer('prop1.prop1subProperty') => myObject['prop1']['prop1subProperty']
  'stringer': function(s){ 
    var a = s.replace(/\[(\w+)\]/g, '.$1').replace(/^\./, '').split('.');
    for(var i in a) if( a[i] in o ) o = o[ a[i] ];
    return o;
  },
  'stringify': function(x){
      var s = function(o){
        var cache = [];
        JSON.stringify(o, function(key, value) {
          if (typeof value === 'object' && value !== null) {
            if (cache.indexOf(value) !== -1) {
              // Circular reference found, discard key
              return;
            }
            // Store value in our collection
            cache.push(value);
          }
          return value;
        });
        cache = null; // Enable garbage collection
      };
    return s( x || this ); 
  },
  // [ toArray() ] : creates an array of an objects values (useful for [arguments])
  'toArray': function(){ var a = [], o = o || this; if( o instanceof Array ) return o; for(var i in o) a.push(o[i]); return a; },  
  // [ count ] : counts the number of properties in an object. if (addLength) is true, .length is added to the object. else, returns length.
  'count': function(addLength){ var i = 0; this.each(function(){ i++; }); return addLength ? ( this.length = i ) : i; },
  'belongs': function(_constructor){ return ( this instanceof _constructor );   },  
//  'empty': {  teacher: true, get: function(){ return this.count()==0 ? true : false; }  },
  // [ has ] : checks if object owns ALL or ANY of the properties provided
  'has': function(){ // optional boolean as last param: (_all) if false, returns true if match ANY, doesn't have to match ALL (default)
    var s = this, a = arguments.toArray(), _all = true, has = false, hasAll = true, hasAny = false;
//    console.log( 'checking '+a+' with options ',_all,has,hasAll,hasAny)
    if( a.length && a.last && a.last.is('boolean') ) _all = a.pop();
    a.each(function(p){ has = s.hasOwnProperty( p ) ? ( !hasAny ? hasAny = true : true ) : hasAll = false; });
    return _all ? hasAll : hasAny;
  },
  // [ pushTo ] : push 'this' to 1 or more or more arrays
  'pushTo': function(){   var x = this; arguments.each(function(a){ try { a.push( x ); } catch(e){ throw new Error('invalid array: cannot pushTo '+e); } }); },
  // [ shiftTo ] : shift 'this' to 1 or more or more arrays  
  'shiftTo': function(){   var x = this; arguments.each(function(a){ try { a.shift( x ); } catch(e){ throw new Error('invalid array: cannot shiftTo '+e); } }); },
  // [ contains ] : converts object to array, searches for value, returns key or false
  'contains': function(v){
    var a = this.toArray();
//    if( a.contains(v) ){ return true }
    return a.contains(v);
  },
/** 
@method switchboard
@chainable
@param cases {Object} object array of cases for this.is( case ) => value == function( this )
@return result {Any} returns whatever the function in case returns
@example {my: object}.switchboard({ 'object': function(){ alert('Yes, this is an object!'); return 'blue'; }) alerts the message and returns the string 'blue'
*/
  'switchboard': function(cases){
    var t = this, r = null;
    if( cases && cases.is('object') ){
      cases.each(function(action, such){
        if( t.is( such ) && action.is('function') ){ r = action( t ); }
      });
      return r;
    }
  }
});

// [ Function ]

master.function.teach({
//  'isFunction' : {  teacher:true, get: function(){ return typeof this === 'function' }  },
//  'extend': function(e){ alert(this); var o = this; o.extend(e); },
  '_deferred': [],
  '_defers': 0,
  '_deferrer': function(value){ return this._deferred[ deferred._defers ][value];  },
  '_deferral': function(method){
    var data = [method].construct('method into every until unless then or',null);
    method._deferred.push( data ); method._defers++;
    return data;
  },
  'defer': function(){ 
    var method = this, args = arguments.toArray();     
    method._deferral( method ); 
    return( function(){ return method.apply( this, args ); } );
  }, //alert('args:'+ method.apply( this, args ).printr ); 
  'autodefer': { teacher: true, get: function(){
      var method = this;
      method._deferral( method ); 
      return method;
    }
  }, //alert('args:'+ method.apply( this, args ).printr ); 
  'into': function(execute){ 
    var deferred = this,
    result = deferred(),
    failed = false,
    method = deferred._deferred[ deferred._defers ]['into'] = ( typeof execute === 'function' ) ? execute : ( failed = true, result );
    if( failed ) throw new Error('Invalid function supplied to deferred.into') 
    else return method;
  }, //returns into( deferredResults )
  /***
  'every': function( ms ){
    var into = this, loop = function(){
      if( deferred._deferrer('until') ){
        if( deferred._deferrer('until')() ) clearTimeout( deferred._deferrer('loop') );
      }
      else { deferred._deferrer('loop') = setTimeout( into, ms ); into(); }  
    }; //end loop
  },
 **/
  'until': function(condition){ if( typeof condition === 'function' ) condition();  },
//  'then': function(fn){ var x = this(); return ( typeof fn == 'function' ) ? fn( x ) : x;   },    
  'andThen': function(fn){ 
    var x = this; 
    if( x.typeof('window') ) throw new Error("'this' is pointing to window (global) context. cannot continue"); 
//    else return ( x.typeof('function') && fn.typeof('function') ) ? fn( x.call(this)() ) : x;   
  },    
//v1  'then': function(fn){ var x = this(); return ( typeof fn == 'function' ) ? fn( x ) : x;   },  
//  'every': function(){ setTimeout( 
    //( [Function].defer(args4function) ).into( nextFunction( deferredResults ).every( xmanyMS ).until( condition = true ).except[when]( conditionisfalse ).then( finallyexectute ).or( fnfailed )
  // [ loop ] : fires a function (times) times. 2nd param: arguments. if array, will be treated as independent arguments to pass for matching execution
  'loop': function(){
    var args = arguments.toArray(), times = args.shift();
    if( this.typeof('function') ) for(var i = 0; i<times; i++) args.length ? this(args, i) : this(i);
  },
  //probably delete of the above ?

  'fire' : master.fire,
//  'form' : master.form,  
  // [ ] : 
//  '' : function(){  },
});


// [ Array ]

master.array.teach({
  'isArray': function(a){ a = a || this; return (a instanceof Array); },
  'delegate': function(list,splitter){ 
    var o = {}, a = this; if(typeof list === 'string') list = list.chop( splitter );
    for(var i in a) o[ list[i] ] = a[i]; return o; 
  },
  'contains' : function(){
    var stack = this, args = arguments.toArray(), count = args.length, 
    check = function(haystack,needle){ for (var straw in haystack){ if( haystack[straw] === needle ) return true; } return false; };
    if( count > 1){ for(var c = 0; c<count; c++){ if( check( stack, args[ c ] ) ) return args[ c ]; } return false; }
    else return check( stack, args[ 0 ] );
  },
  'find': function(v){ var i = this.indexOf( v ); return i!==-1 ? i :  false; },
  'first': { teacher: true, get: function(){ return this[ 0 ]; }, set:function(v){ return this[0] = v; }  },  
  'last': { teacher: true, get: function(){ return this[ this.length - 1 ]; }, set: function(v){ return this[ this.length - 1 ] = v; } },
  'counters': function(v){ return {}.construct( this, 0 ); },
  // [ unite ] : converts an array (track) to a string (train), joined with (spike), modified with (rails) 
  // example: var array = ['I','have','been','united!'] ; array.unite( ' ', { first: 'Yes', 2: 'indeed', last: 'completely'  } ) => "Yes I have indeed been completely united!"
  // special case: array.unite(' ', { middle: '|' } ) => "I | have | been | united!". also accepts before and after ( => " I was added before I have been united! I was added after" )
  'unite': function( spike, rails ){
    var s = spike.is('string') ? spike : ', ',
    r = rails ? rails : rails = spike || {},
    track = this;
    if( rails ){
      if( rails.first ) a.first = rails.first;
      rails.each(function(rail,key){
        switch( key ){
          // case 'first': key = 
        }
        if(key in track) track[key] = rail+track[key];
      });
     o = this.join( s );
    return o;
    };  }
//  'test' : function(test){ alert(test + ' ' + this)}, //works
});


// [ String ]

master.string.teach({
  'is': function(v){
    var s = this, _s = 'string';  return ( master.defined( v ) ? (( v.lowercase === _s ? true  : v.lowercase === s )) : _s );
  },
  'contains': function(needle){ return this.indexOf(needle)>-1;  },
  'chop': function(splitter,callback){ 
    var s = splitter || /(,|\s+|,\s+)/, a = null, r = null;
    if( s.typeof('regex') ){ var m = this.match(s);  if( m.length>1 ) a = this.split( m[1] ); }
    else if( s.typeof('string') ) a = this.split(s);
      else { throw new Error('Invalid splitter provided to String.chop (requires valid regex or string)'); }
    if( callback && callback.is('function') ) r = callback( a );
    return r || a;
  },
  'first': {  teacher: true, get: function(v){ return this[0]; }  },
  'last': {  teacher: true, get: function(v){ return this[this.length-1]; }  },    
//  'first': {  teacher: true, get: function(v){ var f = this[0] ; return ( master.defined( v ) ? (f==v) : f ) }  },
//  'last': {  teacher: true, get: function(v){ var f = this[this.length-1]; return ( master.defined( v ) ? (f==v) : f )  }  },  
  'uppercase': {  teacher: true, get: function(){ return this.toUpperCase(); }  },  
  'lowercase': {  teacher: true, get: function(){ return this.toLowerCase(); }  },
  'capitalize': {  teacher: true, get: function(){ return this.first.uppercase + this.slice(1); }  },
  'append': function(){ var s = this, a = arguments.toArray(), o = s; a.each(function(s){ o += s; }); return o; },
  'prepend': function(){ var s = this, a = arguments.toArray(), o = ''; a.each(function(s){ o += s; }); return o+s; },
  'trim': function(){ var s = this, a = arguments.toArray(), o = s; a.each(function(s){ o += s; }); return o; },
  'space':  {  teacher: true, get: function(){ return this.append(' '); }  },
  'equals': {  teacher: true, get: function(){ return this.append('='); }  },
  'title': {  teacher: true, get: function(){ return this.prepend('[').append(']'); }  },
  'note': {  teacher: true, get: function(){ return this.prepend('(').append(')'); }  },
  'convert': function(original,replaced){   return this.replace(original,replaced); },  
  'dash2underscore': { teacher: true, get: function(){ return this.convert(/-/g,'_'); }  },  
  'underscore2dash': { teacher: true, get: function(){ return this.convert(/_/g,'-'); }  },
  'space2underscore': { teacher: true, get: function(){ return this.convert(/\s/g,'_'); }  },
  'space2dash': { teacher: true, get: function(){ return this.convert(/\s/g,'-'); }  },  
  'strip': function(v){ var s = this, d = /' '/g, r = ( v ? (( v.is('regexp') ? v : ((( v.is('string') ? new RegExp(v,'g') : d ))) )) : d ); return s.replace(v,''); },
  'stripsearch': function(v){ 
    var s = this; 
    if(typeof v !== 'undefined' ){
      if( s.contains( v ) ) return s.strip( v );
      else return false;
    } else if( console && console.warn ) console.warn('String.prototype.stripsearch must receive a value to strip!');
  }
});


// [ Number ]

master.number.teach({
  'inverse': { teacher:true, get: function(v){ v = v || this; return v - (v*2); } }, //remove this for invert
  'invert': { teacher:true, get: function(v){ v = v || this; return v - (v*2); } },  
  'NaN': { teacher:true, get: function(v){ var x = v || this; return x.typeof('number') ? false : x !== x; }  },
  'factor': function( x, v ){ v = v || this; return Math.pow(v,x); },
  'square': { teacher:true, get: function(v){ return Math.pow(this,2); } },  
  'cube': { teacher:true, get: function(v){ return Math.pow(this,3); } },  
  'root': { teacher:true, get: function(v){ return Math.sqrt(this); }  },  
  'type': function(t){ return ( t ? typeof this === t : typeof this ); },  
  'first': { teacher: true, get:  function(){ return this.stringit.split(''); } },
  'last':  { teacher: true, get: function(){ return this.stringit.last; } },
  'within': function(min,max){ return( this>min && this<max ); },
  'temptest': function(){ alert( this ); },
  'whole': { teacher: true, get: function(){ return ( this % 1 === 0 ); } },
  'positive': function(){ return ( this > 0 ); },  
  'round': function(){ return Math.round( this );  },
  'remainder': function(d,asObject){ var v = this, w = (  (v/d).round() * d  ), r = (  v - (w*d)  ); return asObject? { whole: w, remainder: r } : r; },  
  'fracture': function(d){ return this.remainder( d || 1, true ).merge(); },
  // [ nearest ] : advanced rounding. finds (n)earest and rounds as appropriate. !n == works just like Math.round()
  'nearest': function(n){
    // value, divisor, whole, remainder    
    var v = this, d = n || 1, f = v.fracture(d), w = f.whole, r = f.remainder;
//    console.log(d, ' ', w, r )
    return ( r <= d  ? w : w+d ) || 0;
  },
  // [ at ] : checks the number. when the number == (value), the (callback) is fired. returns true if at
  'at': function(value, callback, options){
    var n = this;//, v = Number(value );
//log(n.is(), v.is())
    if( n === value ){ 
      if( callback && callback.is('function') ){ callback( value, options || false ); }
      return n;
    } else return false;
  },
  'rad2deg': { teacher: true, get: function(){ var r = this; return (r*180)/Math.PI; }  },
  'deg2rad': { teacher: true, get: function(){ var r = this; return (r/180)*Math.PI; }  },  
  'px': {  teacher: true, get: function(){ return this+'px'; }  },
  'odd': {  teacher: true, get: function(){ return this & 1;}  },
  'even': {  teacher: true, get: function(){ return !this.odd; }  },
  'finite': {  teacher: true, get: function(){
      var i = this, p = i.POSITIVE_INFINITY, n = i.NEGATIVE_INFINITY;
      if( p || n || i.NaN ) return false;
      else return true;
    }
  },
  //new
  each: function(fn){
    var n = this;
    if( _proto_.defined(fn) && fn.is('function') ){
      for(var i = 0; i<n; i++) fn(i);
    }
  }
});
  //alert('returning master');
  if( typeof module !== 'undefined' ) module.exports = master;
  if( typeof define !== 'undefined' ) define('prototype',function(){ return master; }); //for requirejs
  return master;
  
}());