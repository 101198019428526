module.exports = (function(DEBUG){
/******************************************************************************/

  /*
   * Scripting by Casey Dwayne. All rights reserved.
   * @file form.js
   * utility for validating form inputs prior to submit
   */

/******************************************************************************/

  /*
   * @property form_issue_internals
   * @prototype
   * Merges with form.issues; houses issue-related entities
   */

  var form_issue_internals = {
    //styles an individual [issue] message
    style: function( m, t ){
      return "<span class='form-message form-message-"+t+"'>" + m + "<br></span>";
    },
    //the place where issues will show (can be set on function level)
    place: null,
    //removes an issue_element from front-end
    remove: function( issue_element ){
      ( issue_element || this ).remove();
    },
    //time to wait until removing issue_element
    remove_after_timeout: 0,
    //queues an issue_element to be removed
    remove_after: function( issue_element ){
      var s = this;
      var t =  s.issue_delete_after_timeout;
      if( DEBUG > 1 ) console.log( 'Issue will delete after '+ t + 'ms' );
      setTimeout( s.remove, t, issue_element );
    },
    //internal-ish method of Form_Issue: accepts a message, method, place, and type
    message: function( message, method, place, type ){
      var i = this;
      var p = place || i.place;
      var m = i.style( message, type );
      var e = $(m);
      switch( method ){
        case 'after':
          p.after( e );
          i.resolve = function(){
            i.remove( e );
          };
          if( i.remove_after_timeout ) i.resolve();
        break;
        case 'append':
          p.append( e );
          i.resolve = function(){
            i.remove( e );
          };
        break;
        case 'overwrite':
          p.html( m );
          i.resolve = function(){
            p.empty();
          };
        break;
        default:
          console.log( message );
        break;
      }
    },
    //public method for throwing error
    error: function( m, x, p ){
      if( this.show_message_type ) m =  'Error: '+ m;
      this.message( m, x, p, 'error' );
    },
    //public method for showing warning
    warning: function( m, x, p ){
      if( this.show_message_type ) 'Warning: ' + m;
      this.message( m, x, p, 'warning' );
    },
    //priority level overrides higher level warnings (1 = max, 1>2, 0 = lowest/no priority)
    priority: 0,
    //show Warning: or Error: before message
    show_message_type: false
  };

  var Checker = function Checker( fn ){
    // checker module
    var checker = {
      validation_function: function(){ console.log( 'Set a validation_function first!' ); },
      checks: {},
      queue: function( name, delay ){
        var c = this;
        if( DEBUG > 3 ) console.log( 'Queing check for', name, '('+delay+')' );
        return c.checks[ name ] = setTimeout( c.validation_function, delay );
      },
      clear: function( name ){
        var c = this;
        if( DEBUG > 3 ) console.log( 'Clearing check for', name, c.checks[ name ] );
        var t = c.checks[ name ];
        clearTimeout( t );
        return t;
      },
      insert: function( name ){
        return this.queue( name, 4000 );
      },
      recheck: function( name, delay ){
        var prev = this.clear( name );
        var t = this.queue( name, 750 );
        if( DEBUG > 3 ) console.log( 'Clearing check for', name, prev + ', Adding', t );
        return t;
      }
    };
    if( typeof fn === 'function' ) checker.validation_function = fn;
    this.merge( checker );
    return this;
  };

  /*
   * @method Form_Issue
   * @constructor
   * Creates a Form_Issue to be mangaged later.
   * @returns self
   */

  var Form_Issue = function Form_Issue( type, message, name, method, place ){
    var s = this;
    s.details = { type: type, message: message, name: name, method: method, place: place };
    //s.details = dsb.construct( 'type, message, name, method, place', type, message, name, method, place );
    s.merge( form_issue_internals );
    return s;
  };


  /*
   * @property form_properties
   * @prototype
   * Merges with Form constructor; houses various settings & operations
   */

  var form_properties = {
    // issue-related properties
    issues: {},
    errors: 0,
    //__issues_active: [],
    issue_active: function( name ){
      var active = this.issues[ name ] || false;
      if( DEBUG > 2 ) console.log( 'Checking if condition "'+ name +'" is active:', active );
      return active;
    },
    issue_activate: function( type, message, codename, method, place ){
      var i = new Form_Issue( type, message, codename, method, place );
      i.name = codename;
      if( DEBUG > 1 ) console.log( 'Adding issue', codename, 'to form.issues.' );
      if( type === 'error' ) this.errors++;
      //create GUI response
      var el = i[type]( message, method, place );
      this.issues[ codename ] = i;
      return i;
    },
    issue_resolve: function( issue ){
      if( issue.resolve ) issue.resolve();
      if( issue.details.type === 'error' ) this.errors--;
      delete this.issues[ issue.name ];
      if( DEBUG > 1 ) console.log( 'Issue '+issue.name+' was resolved; removing issue', issue.name, 'from form.issues.' );
      return issue;
    },
    issue: function( type, message, codename, method, place ){
      var x = this;
      var a = x.issue_active( codename );
      //console.log( 'Creating issue', codename, 'type:', type );
      if( a ) return a;
      //only add issue if it isn't currently active (for better UX)
      else {
        //add this codename to active issues
        var i = x.issue_activate( type, message, codename, method, place );
        //return Form_Issue
        return i;
      }
    },
    issue_limit: 1, //limits the number of issues visible [per field_name] at one time
    create_issue: function( params, handle, place ){
      var prob_type = params.type;
      var method = 'after';
      var place = place;
      //method = place = false; //for testing.
      //check if issue is active via handle
      if( this.issue_active( handle ) ){
        console.log( 'Issue '+handle+' is already active: waiting for resolution.');
        return this.issue_active( handle );
      } else {
        //create issue if not already active
        return this.issue( prob_type, params.message, handle, method, place );
      }
    },
    has_issues: function(){
      if( this.issues.length ) return this.issues;
      else return false;
    },
    has_errors: function(){
      return this.errors;
    },
    // validation function (form.validate)
    validate: function( validation_controllers ){
      var f = this;
      var controllers = validation_controllers;
      controllers.each(function( specs, field_name ){

        //the (usually 'input') element where value exists
        var el = f.element.find('*[name='+field_name+']');

        if( DEBUG ) console.log( 'Checking validation specs for', field_name );

        var validate_specs = function(){
         specs.each(function( properties, handle ){
            var p = properties;
            var met = p.condition( el.val() );
            if( met ) {
              if( DEBUG > 2 ) console.log( 'Condition met: '+ handle +' returning true' );
              var i = f.create_issue( properties, handle, el );
              i.field_name = field_name;
            }
            else {
              var active_issue = f.issue_active( handle );
              //check if issue is active via handle
              if( active_issue ){
                if( DEBUG > 2 ) console.log( 'Issue '+handle+' is active: resolving.');
                //clear issue
                f.issue_resolve( active_issue );
              }
            }
          });
        };

        var checker = new Checker( validate_specs );

        //event triggers
        el.on( 'keyup', function(){
          if( checker.checks[ field_name ] ) checker.recheck( field_name );
          else checker.insert( field_name );
        });


      }); //end controllers.each
      return f;
    },
    // setting-related properties (  form.settings( {...} )  )
    __settings: {
      validateWhileTyping: true,
      validateOnSubmit: true
    },
    settings: function( settings ){
      var f = this;
      if( typeof( settings ) === 'object' )
        f.__settings = dsb.merge( f.__settings, settings );
      return f.__settings;
    }
  };


  /*
   * @method Form
   * @constructor
   * Creates a Form factory; contains above properties
   * @returns self
   */

  var Form = function Form( entity ){
    var form = this;
    form.merge( entity, form_properties );
    //this.test = true;
    return form;
  };


  /*
   * @property forms
   * @dsb-internal
   * creates the internal dsb.forms object for handling forms
   */

  var forms = {
    total: 0,
    registered: { /* '{{form_id}}': {{Form_Element}} */ },
    __create: function( form_object ){
      var f = new Form( form_object );
      return f;
    },
    register: function( id ){
      var c = $( '#'+id );
      var e = c.find( 'form' );
      var o = { form_id: id, element: e };
      var f = this.__create( o );
      this.registered[ id ] = f;
      this.total++;
      var fid = e.find( 'input[name=form_id]' ).val();
      if( DEBUG > 3 ) console.log( 'Registered form', this.total, '(#'+ id +' -> "'+ fid +'")' );
      return f;
    },
    getForm: function( form_id ){
      if( this.registered[ form_id ] ) return this.registered[ form_id ];
      else {
        if( DEBUG ) console.log( form_id + 'does not exist or is not registered.' );
        return false;
      }
    },
    __Form: Form
  };

/******************************************************************************/

    //hijack forms to improve UI

  var hijack_forms = (function(){

    var TEST_MODE = true;

    var _forms = $('form');
    if( _forms.length ){

      //prevent submission if time on page less than 5 seconds
      var prevent_submission = true;
      var stop_prevention = function(){
        prevent_submission = false;
      };
      setTimeout( stop_prevention, 5000 );

      _forms.on( 'submit', function(e){

        e.preventDefault();

        var t = e.target,
            a = t.getAttribute('action'),
            f = $(this); //should be the form

        //alert(a);

        var o = $(document.createElement('div'));
        o.addClass('w100p absolute bottom left flex');
        o.css('z-index', 99999);
        var overlay = function( html ){
          o.html( html );
          t.appendChild(o[0]);
          o.on('click',function(){ o.remove(); });
        };
        var overlay_message = function( problem, details ){
          var h = $('<div class="form-response form-response-failed"></div>' );
          h.append( $('<h4>' + problem + '</h4>') );
          h.append( $('<p class="text-small">' + details + '</p>') );
          overlay( h );
        };


        if( prevent_submission ) {
          /*
          var d = $('<div></div>');
          var h = $('<h2>Bot Detected!</h2>');
          var p = ( $('<p class="text-small">If you are human, please wait 5 seconds and try again.</p>' ) );
          d.append(h);
          d.append(p);
          overlay( d );
          */
          overlay_message( 'Bot Detected!', "If you are human, please wait 5 seconds and try again." );
          return;
        }

        var empty = true;
        f.find( 'input, textarea' ).each(function(x){
          var t = $(this);
          if( DEBUG > 4 ) console.log( 'Found', t.attr('name') );
          var n = t.attr('name');
          if( n !== 'R0B0T' && n !== 'form_id' ){
            var v = t.val();
            if( DEBUG > 4 ) console.log( 'Found value:', v );
            if( v !== '' ) empty = false;
          }
        });
        if( empty ){
          /*
          console.log( 'form is empty' );
          var h = $('<h3>Could not submit:</h3>');
          h.append( $('<p class="text-small">Detected an empty form... please fill it before sending!</p>' ) );
          overlay( h );
          */
          overlay_message( 'Could not submit:', "Detected an empty form... please fill it before sending!" );
          return;
        }

        var form = forms.getForm( f.parent()[0].id ); //buggy as hell, change this.
        //form validation
        if( form && form.settings().validateOnSubmit ){
          //alert('validating form');
          if( form.has_errors() ){
            var iss = $( '<ul class="form-issues"></ul>' );
            form.issues.each(function( issue, name ){
              var n = issue.field_name;
              n = n[0].toUpperCase() + n.slice( 1 );
              iss.append( $( '<li class="form-issue">' + n + ': ' + issue.details.message + '</li>' ) );
              if( DEBUG > 3 ) console.log( 'form issue:', name, issue );
            });
            var html = $('<div></div>');
            html.append( $('<p>Please resolve the following errors:</p>') );
            html.append( iss );
            overlay_message( 'Could not submit!', iss.html() );
            return;
          }
        }

        $.ajax( a, {
          type: "POST",
          data: f.serialize(),
          beforeSend: function(){
            o.html('Processing, please wait...');
            t.appendChild(o[0]);
            o.on('click',function(){ o.remove(); });
          },
          success: function(r){
            o.html(r); //<-- in production this is correct
          },
          error: function(e){
            if( TEST_MODE ){
              o.html('<div class="form-response form-response-successful"><h5>Submission Successful</h5><p>This is a test from the javascript file.</p></div>');
              //o.html('<div class="form-response form-response-error"><h5>Submission Error</h5><p>This is an error from the javascript file.</p></div>');
            }
            else {
              o.html('<div class="form-response form-response-failed"><h5>Submission Failed</h5><p>Sorry for the inconvenience. Try again?</p></div>');
            }
          }
        });

      }); //end onSubmit

    }

  });

  dsb.relay( hijack_forms );


/******************************************************************************/

  return forms;

/*----------------------------------------------------------------------------*/
}(0));
