module.exports = (function(DEBUG){
/******************************************************************************/

  /*
   * Scripting by Casey Dwayne. All rights reserved.
   * @file scroll.js
   * this file controls scroll functionality.
   * automatically works with hash tags (#) in anchors and URLs.
   */

/******************************************************************************/

/*
  * @method scrollToY
  * scrolls screen to a particular Y coordinate (Y = top of page)
  * @param {number} scrollTargetY: the target scrollY property of the window
  * @param {number} speed: time in pixels per second
  * @param {string} easing: easing equation to use
  */

function scrollToY(scrollTargetY, speed, easing) {

  //initial vars/default settings
  var scrollY = window.scrollY || document.documentElement.scrollTop,
      scrollTargetY = scrollTargetY || 0,
      speed = speed || 2000,
      easing = easing || 'easeOutSine',
      currentTime = 0;

  // min time .1, max time .8 seconds
  var time = Math.max(.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, .8));

  // easing equations from https://github.com/danro/easing-js/blob/master/easing.js
  var easingEquations = {
    easeOutSine: function (pos) {
      return Math.sin(pos * (Math.PI / 2));
    },
    easeInOutSine: function (pos) {
      return (-0.5 * (Math.cos(Math.PI * pos) - 1));
    },
    easeInOutQuint: function (pos) {
      if ((pos /= 0.5) < 1) {
        return 0.5 * Math.pow(pos, 5);
      }
      return 0.5 * (Math.pow((pos - 2), 5) + 2);
    }
  };

  //animation loop
  function tick() {
    currentTime += 1 / 60;

    var p = currentTime / time;
    var t = easingEquations[easing](p);

    if (p < 1) {
      requestAnimFrame(tick);
      window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t));
    } else {
      //if( DEBUG ) console.log('scroll done');
      window.scrollTo(0, scrollTargetY);
    }
  }

  // call it once to get started
  tick();
};

//-setTimeout(function(){ window.scrollTo( document.body, 0, 0 ); }, 30 );


/*
  * @method offsets
  * accepts an element or array of them. gets/adds outerHeight and returns sum.
  * @param elements {object|array|string} element, element name, or array of elements/names
  * @param _offset {number} obsolete; previously set 0 to offset
  * @return offset {number} the amount to offset scrollToY element
  */

var offsets = function(x,_offset){
  var o = 0;
  if( x instanceof Array )
    for( var i in x ) o += $( x[i] ).outerHeight();
  else
    o = $(x).outerHeight();
  return o;
};

/*
  * @method scrollToFragment
  * activates scrollToY with [offset] adjusted value
  * @param element {object} the target element to scroll to
  */

var scrollToFragment = function(f){
  var x = $(f),
      o = offsets(['#header']) || 0,
     _h = $(window).height(),
      h = _h - o,
     eh = x.outerHeight(),
      e = eh > h; //container taller than available space?
  var split = false; //sok
  if( DEBUG ) console.log({
    'element': x.id || x[0],
    'offset': o,
    'element_taller_than_window': e
  });
  if( x || x.length ){
    var y = x.offset().top;//x[0].offsetTop; offsetTop goes from parent container, not window.
    var d_o = new Number( f.attr('data-scrollOffset') );
    var data_offset = isNaN(d_o) ? 0 : d_o;
    var offset = o + data_offset;
    var p = y - ( offset );
    if( split ) p = p - ( ( _h / 2 ) - ( eh / 2 ) );// - offset;
    if( DEBUG ) console.log( 'scrolling to Y with options', {
      'final value': p,
      'element at': y,
      'offset': o,
      'split': split,
      'winHeight': _h,
      'data offset': data_offset,
      'wtf': true
    });
    if( y > 0 ) scrollToY( p );
  } else {
    if( DEBUG ) console.log( 'Could not find element for', f );
  }
};

// add scrollToY to global scope
window.scrollToY = scrollToY;
// add scrollToFragment to global scope
window.scrollToFragment = scrollToFragment;

//check for hash in URL and activate if present
var scrollY_on_load = function(){
  var h = window.location.hash;
  if( h ){
    if( DEBUG ) console.log( 'Found hash '+h+', scrolling.' );
    try {
      scrollToFragment( $(h) );
    } catch (e) {
      //alert(e)
      //warn element with this ID does not exist
    }
  };
};

/*
$(document).ready(function(){
  setTimeout( scrollY_on_load, 2000 );
});
*/
$(window).on( 'load hashchange', function(){
  //console.log('Scrollling');
  scrollY_on_load();
});

//activate scrollToFragment on anchor click (if '#' plus element exists)
$(document).on( 'click', 'a', function(e){
  var _url = this.getAttribute('href');
  // check clicked anchor for hash fragment
  if( _url && _url.indexOf('#') > -1 ) {
    var h = _url.split('#').pop(),
       el = $('#'+h);
    // check that hash target exists before preventing default behavior
    if( el.length ) {
      e.preventDefault();
      if( DEBUG ) console.log('Scrolling to...','HASH',h);
      scrollToFragment( el );
    }
  }
});

/******************************************************************************/

  return dsb;

/*----------------------------------------------------------------------------*/
}(0));
