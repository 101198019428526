(function(){

if(typeof dsb === 'undefined') dsb = { log: function(m){ if(console && console.log) console.log(m); } };
/*********************************************************************/
// [ dsb -> frame ]
/*********************************************************************/
dsb.frame = {
  
  framed: false,
  
  wrap: {
    target: '#wrap',
    sticky: false,
    stretch: true
  },
  
  header: {
    target: '#header',
    wrap: {
      target:'header',
      sticky: true
    },
    sticky: false
  },
  
  content: {
    target: '.content',
    wrap: {
      target:'#content-wrap',
      stretch: false
    },
    stretch: true,
    spread: false
  },
  
  footer: {
    target: '#footer',
    wrap: { 
      target: 'footer',
      sticky: false
    },
    sticky: false
  },
  
  overlay: {
    target: '#overlay'
  },
  
  underlay: {
    target: '#underlay'  
  },
  backdrop: {
    target: '#backdrop'
  }
    
};

/*********************************************************************/
// [ Content ] 
/*********************************************************************/

dsb.frame.content.init = function(){


  // [ Commons ]  

  var c = dsb.frame.content,
   self = $(c.target),
      o = self.offset().top,    
      h = self.height(),
      m = self.outerHeight(true),    
      w = $(c.wrap.target),
     wo = w.offset().top,
     wh = w.height(),
     wm = w.outerHeight(true),
      f = $(dsb.frame.footer.wrap.target).outerHeight(true),
    win = window.innerHeight;


  // [ Stretch Content ] : window - (offset+footer)+(content whitespace*2)
  
  c._stretch = function(){
    dsb.log('stretching content');
    
    var pad = ( m-h )*2,
        min = win - ( o + f + pad );
    //alert( 'stretching content to '+ min );
    self.css({ 'min-height': min });
  };
  
  if(c.stretch) c._stretch();


  // [ Spread Content ]
  
  c._spread = function(){
    //dsb.log('cannot spread content, stretched to max');
    if(c.stretch) return false;
    dsb.log('spreading content');
    var a = win - ( ( o+f )+( m-h ) ),
    d = (a-h)/2;
    if(h<a) {
      dsb.log('adding vertical margins to content '+d);
      self.css({'margin-top':d,'margin-bottom':d});
    };
  };
  
  if(c.spread) c._spread();


// [ Stretch Content Wrap ]
  
  c.wrap._stretch = function(){
    dsb.log('stretching content wrap');
    var r = win - ( ( wo+f )+( wm-wh ) );
    dsb.log('setting c-wrap min-height to '+r);
    if(wh<win) w.css('min-height',r);      
  };
  
  if(c.wrap.stretch) c.wrap._stretch();

};// end content.init

/*********************************************************************/
// [ Grab Frame Element ] 
/*********************************************************************/

dsb.frame.grab = function(element){ return dsb.frame.stack(element).self; };

/*********************************************************************/
// [ Frame Init ] 
/*********************************************************************/
       
dsb.frame.solidify = function(){ dsb.fn.solidify(dsb.frame); }, 
        
dsb.frame.setup = function(){  

  if( dsb.frame._init ) return;
  
  dsb.log('setting up frame');
  
  dsb.frame.solidify();
  
  //turn targets into jQuery objects
  //var frame = 
  //dsb.fn.solidify(dsb.frame); //must call before declaring vars!
  //$$$=frame.header
  // [ Commons ]
  
  var x = dsb.frame,
  w = x.wrap.self,
  h = x.header.self,
  hw = x.header.wrap.self, //x.header.wrap.self
  f = x.footer.self,
  fw = x.footer.wrap.self,
  c = x.content.self,
  cw = x.content.wrap.self,
  spoof = dsb.fn.spoof,  
  y = function(){
    return( $('html').scrollTop());
  },
    
  /* @method cling
   * creates a fixed object that clings to the sides of its relative container
   * @param {type} object
   * @param {type} direction
   * @param {type} offset
   * @returns {undefined}
   */

  cling = dsb.frame.cling = function( o, d, s ){
    if(typeof d === 'undefined') d = 'top';
    var s = s || 0,
    c = { 'position':'fixed', 'z-index':99 };
    c[d] = s;
    //works for most browsers
    o.css(c);
    //works for all browsers
    //var move = function(v){ dsb.log('scrolling to '+y() ); o.css({ }); };
    //bind to scroll
    //$(document).one('scroll',move);
    //logger
    var ls = 'clinging ['+o.selector+'] to '+d;
    //dsb.log( s ? ls+' with '+s+' offset' : ls );
  };   
  
  // [ Hide ]
  
  var $fn = dsb.fn;
  
  
  dsb.frame.hide = function(){    
   [h,cw,f].each(function(o){     
     if( dsb.fn.isjQuery(o) ) o.hide();
     else {
       if( o.style ) o.style.display = 'none';
       else dsb.warn('Could not hide '+o);
     }
   });
  };  
  
  // [ Show ]
  
  dsb.frame.show = function(){
    [h,f,cw].each(function(o){ o.show(); });
  };
  
    // [ Framed ]
    
    dsb._framed = function(){
      $('body').css('overflow','hidden');
      cw.css('overflow','scroll');
    };
               
  
    // [ Sticky Wrap ]
    
    if(x.wrap.sticky){
      alert('sticky wrap');
    }

    // [ Stretch Wrap ]    
    x.wrap._stretch = function(){
      var h = window.innerHeight;      
      if( f.is(':visible') && !x.footer.sticky ) h = h-f.outerHeight();
      if( x.wrap.stretched !== h  ){ 
        x.wrap.stretched = h;          
        dsb.log('stretching wrap to '+h);
        x.wrap.self.css('min-height',h);
      }
    };

    // [ Sticky Header Wrap ]
    var t = x.header.wrap;
    t._sticky = function(){
      if( !t.stuck ){
        dsb.log('sticking header wrap');      
        spoof(hw); 
        cling(hw);
        t.stuck = true;
      }      
    };

    // [ Sticky Header ]

    x.header._sticky = function(){

      dsb.log('sticky header');
      //dsb.fn.spoof(h);
      var s = function(){ cling( y, h ); };
      //$(document).ready(s).on('scroll',s);

    };

    // [ Sticky Footer Wrap ]
    
    var t = x.footer.wrap;
    t._sticky = function(){
      if( !t.stuck ){
        dsb.log('sticking footer wrap');
        //spoof(fw);
        t.stuck = true;
      }
      cling(fw,'bottom');
    };

    // [ Sticky Footer ]
    
    x.footer._sticky = function(){
      dsb.log('sticky footer');
      cling(f,'bottom');
    };        
  
  
  // [ Init ]
  
  dsb.frame._init = false;
  
  var init = dsb.frame.init = function(){
    
    dsb.log('initializing frame');    
    //if( dsb.frame._init ) return;
    // [ Content ] *IMPORTANT* keep first AND last
    dsb.frame.content.init();
    
    if(x.framed) dsb._framed();
    if(x.wrap.stretch) x.wrap._stretch();
    if(x.header.wrap.sticky) x.header.wrap._sticky();
    if(x.header.sticky) x.header._sticky();
    if(x.footer.wrap.sticky) x.footer.wrap._sticky();
    if(x.footer.sticky) x.footer._sticky();
    dsb.frame._init = true;
    
    // [ Content ] *IMPORTANT* keep first AND last
    dsb.frame.content.init();
  }; //end init

  //init frame 1st time
  init();  
    
  
};

dsb.frame.backdrop.fill = null;
dsb.frame.backdrop.active = null;
dsb.frame.backdrop.onChange = null;
dsb.frame.backdrop.opacity = null;

////////////////////////////////////////////
// Change Backdrop
////////////////////////////////////////////
dsb.frame.backdrop.change = function(image,options){
  
  var _DEBUG = 0;
  
  var defaults = {
    repeat: false,
    effect: 'fade',
    duration: 600,
    enter: 'fadeIn',
    exit: 'fadeOut'
  };
  
  if( typeof options !== 'object' ){
    options = defaults;
  } else options = dsb.fn.merge.call( defaults, options );
  
  
  dsb.log('changing backdrop');
  var b = $(dsb.frame.backdrop.target);      
    
  if( !dsb.frame.backdrop.opacity ) dsb.frame.backdrop.opacity = b.css('opacity');
      
  var a = dsb.frame.backdrop.active,      
      o = dsb.frame.backdrop.opacity,   
      r = options.repeat || false,
      t = options.duration;  
  
  if( image.contains('/') ){
   //leave alone, assume accurate URL is provided
  } else {
    //var change = function(image){
      var r = dsb.config ? dsb.config.site.url : '',
          f = 'files/media/backdrops/';
          p = r+f;
      if( image.contains('.') ) p = p+image;
      else p = p+image+'.jpg';
      image = p;
    //};
    //change(image);
  }
  var change = function(bg,img){
    var b = bg ? $(bg) : $(dsb.frame.backdrop.target);
    var i = img || image;
    if( r ) b.css({ 'background-repeat': r });      
    if( i ) b.css({ 'background-image': 'url('+i+')' });
    var cb = dsb.frame.backdrop.onChange;
    if( typeof cb === 'function' ) cb();
  };
  var fade = function(){    
    b.animate({ 'opacity': 0 }, t, function(){      
      change();
      b.animate({ 'opacity': o }, t );
    });
  };
  var inter = function(img){
    var d = $(document.createElement('div'));
    d.addClass('fullscreen fixed');
    change(d,img);
    return d;
  };
  var slide = function(){
    if( a ){      
      var c = inter(a),
          n = inter(image),
          w = $(window).width(),
          d = options.duration;
      c.css({
        left: w,
        'z-index': 99
      });
      n.css({
        left: 0,
        'z-index': 100
      });      
      b.append(c);
      b.append( n );
      var finish = function(){
        change();
        c.remove();
        n.remove();
      };
      c.animate({ 'left': -w  }, t );
      n.animate({ 'left': 0  }, t, finish );      
    } else fade();    
  };
  
  if( image !== dsb.frame.backdrop.active ){
    var fx = options.effect;
    if( _DEBUG ) console.log( 'changing backdrop to', image, 'using', fx );
    if( fx === 'slide' ) slide();
    else fade();
    dsb.frame.backdrop.active = image;
  }
  //else do nothing, backdrop is the same
};

var _launch = function(){
  //alert('frame init');
  dsb.frame.init();
  dsb.frame.content.init();
  setTimeout( dsb.frame.init, 30 ); //somewhere it lags, messing up dimensions
};

dsb.frame.activate = function(){
  $(document).ready( _launch );
  
  
  $(window).on('resize', _launch);
  
};

if( typeof module !== 'undefined' ) module.exports = dsb.frame;
/*********************************************************************/
}());