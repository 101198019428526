(function(){
  
  var path = require('path');
  /* @method Loader
   * @constructor
   * @param {Function} callback
   */
  
var loader = function Loader(callback){
    
    /* @property {Boolean} loading
     * @property {Boolean} loaded
     * @property {Array} list
     */
    var _loader = this;    
    _loader.loading = false;
    _loader.loaded = 0;
    _loader.started = false;
    _loader.finished = false;
    _loader.list = [];


    /* @method total     
     * @return {Number}
     * returns the total # of scripts in list
     */
    
    _loader.total = function(){
      return _loader.list.length;
    };

  
    /* @method add
     * @param {String} file
     * add file to list
     */

    _loader.add = function(f){
      var p = path.resolve( f );
      _loader.list.push( f );
      _loader.debug('Added '+f);
    },


    /*@method check
     * check if list is loaded
     */
     
    _loader.check = function(){      
      var done = _loader.total() === _loader.loaded; 
      _loader.debug('Checking.. done? '+done);
      if( done ) _loader.finish();
      else _loader.proceed();
      return done; 
    },


    /* @method _load
     * load actual script
     */
     
    _loader._load = function(i){
      var l = _loader, script = l.list[ i || 0 ];
      l.debug( 'Loading script %d of %d: ', i+1, l.total(), script );      
      var root = 'http://192.168.1.17/portfolio',
          //root = '../../../..',
          file = script;
      requirejs.config({baseUrl:root});
      var v = requirejs( [ file ], l.check, l.error );                    
      l.loaded++;      
    },

    /* @method proceed
     * proceed to next script in list
     */
     
    _loader.proceed = function(){
      _loader.debug( 'Moving to next script' );
      _loader._load( _loader.loaded );      
    },

    /* @method start
     * kick starts the loader, returns when done.
     */          
     
    _loader.start = function(callback){
      if( _loader.finished ) return true;
      _loader.loading = _loader.started = true;
      _loader.debug('Starting');
      _loader.callback = callback;         
      
      //I just said screw it and made them async
      requirejs( _loader.list, _loader.finish, _loader.error );
            
      //_loader.proceed();
    },
            
    /* @method finish
     * flips flags, calls complete()
     */
    _loader.finish = function(){      
      _loader.finished = true;
      _loader.loading = false;
      _loader.debug('Finished. Loaded '+_loader.loaded+' scripts');      
      _loader.complete( _loader.callback );
    },
            
    /* @method complete
     * runs callback
     */
     
    _loader.complete = function(callback){
      _loader.debug('Starting');
      if( typeof callback === 'function' ) callback();
      else alert('loader has no callback');      
    },
    
    _loader.error = function(m){
      alert('Loader Error: ' + ( m || 'Generic') );
      _loader.debug('Firing Error');      
    },
            
    _loader.test = function(){
      _loader.debug('Firing Test');
      alert('Loader Test Succesful');
    };
    
    _loader.debug_mode = false;
    
    _loader.debug = function(m){
      if( console && _loader.debug_mode ){      
        var a = arguments.toArray();
        a[0] = 'Loader debug: '+a[0];
        console.log.apply(null,a);
      };
    };
    
  };
  
  loader.create = function(cb){ return new loader(cb); };
  
  if( module ) module.exports = loader;
  
  return loader;
  
}());