/*********************************************************************/
// for unit testing
/*********************************************************************/
if(typeof dsb === 'undefined') dsb = { log: function(m){ console.log(m) } };

/*********************************************************************/
// [ custom events ]
/*********************************************************************/
dsb.event = {

    /* @property core    
    * @type Node.Events
    * Node's Event factory
    */

    core: require('events'),
    
    /* @property emitter 
     * @private
     * @type EventEmitter
     */
  
    _emitter: null,

    /* @method emitter
    * returns the EventEmitter (creates a new one if none found)
    * @returns {EventEmitter Object}
    */
    
    emitter: function(){
      return this._emitter ? this._emitter : this._emitter = new this.core.EventEmitter();
    },
    
    /* @property events
     * stores custom events { name: Event }
     * @type object    
     */
    
    events:{},

    /* @method trigger
    * creates a custom event
    * @param {string} event name
    * @param {function} listener handler
    */
   
    create: function(event,listener){
      var e = this.emitter();      
      return this.events[ event ] = e.addListener( event, listener );
    },
    
    on: function(e,l){
      this.create(e,l);
    },
    
    /* @method trigger
     * triggers a custom event
     * @param {string} event name
     * @param {function} listener handler
     * @param {boolean} override pre-bound function
     * @returns {listeners} returns listeners or false if no handler function
     */
    trigger: function(event,listener,override){      
      var e = this.emitter(), self = this,
      create = function(){ self.create( event, ( typeof listener === 'function' ? listener : function(){} ) ); };      
      if( !self.events[ event ] || override ) create();
      return e.emit( event );
    }
    
/*********************************************************************/
};//end dsb.event
/*********************************************************************/
if( typeof module !== 'undefined' ) module.exports = dsb.event;