module.exports = (function(DEBUG){
/*----------------------------------------------------------------------------*/

  "use strict";

  var __ = require('./prototype');
  
/******************************************************************************/

/*
 * @module dsb
 * Dynamic Site Builder: globally accessible object 
 * @version 1.7.3
 */

/*----------------------------------------------------------------------------*/

  /* 
   * @property options
   * dsb options
   * @type object
   */

  var options = {
    
    logger: {
      disable: true,
      style: true, 
      target: document.getElementById('wrap')
    },
    
    show: {
      setup: false
    },
    
    debug: true
    
  };

/******************************************************************************/      

  /*
   * @property dsb
   * the master DSB object
   * @type object
   */
  
  var dsb = {

/*----------------------------------------------------------------------------*/
  
    /* 
     * @property data
     * DSB and site related config data
     * @type {Object}
     */

    //data: require('../../config.json'),


    /* 
     * @property self
     * Path to DSB files
     * @type {String}
     */

    self: 'system/dsb/',


    /* 
     * @property ready
     * Signifies if DSB has been loaded once
     * @type {Boolean}
     */

    ready: false,     


    /* 
     * @method setup
     * performs each step required to launch DSB   
     * 1. load dependencies
     *   a. ready the dsb
     *   b. process queue
     */

    options: options,


    /* 
     * @propery dependencies
     * DSB scripts to use from start
     * @type {Array}
     */

    dependencies: [ 'functions', 'site', 'frame', 'ajax', 'html', 'animations', 'event' ],


    /* 
     * @propery scripts
     * DSB scripts with full path
     * @type {Array}
     */

    scripts: [],


    /* 
     * @property fn
     * functions
     * @type {object}
     */

    fn: require('./functions'),
    //site: require('./site'),
    frame: require('./ui/frame'),
    //ajax: require('./ajax'),
    html: require('./ui/html'),
    animation: require('./ui/animations'),
    event: require('./utils/event'),



    /* 
     * @property queue
     * creates a new queue
     * @type {object}
     */

    queue: require('./utils/queue').create(),


    /* 
     * @property loader
     * creates a new loader
     * @type {object}
     */

    loader: require('./utils/loader').create(),


    /* 
     * @property logger
     * creates a new logger
     * @type {object}
     */

    logger: require('./utils/logger').create(options.logger),

    //? integrate( loader );


    /* 
     * @property node_modules
     * stores node.js modules
     * @type {object}
     *

    node_modules: {
      events: require('events')
    },


    /* 
     * @method node_module
     * returns a specific node module
     * @param {type} module
     * @return {node_module}
     *

    node_module: function(module){
      return this.node_modules[ module ];
    },

  

/******************************************************************************/
// [ generic functions ]
/******************************************************************************/


    /*
     * @method log
     * @param message {Any}
     * @param [priority] {Number}
     * generic log function
     */

    log: function(){
      var options = dsb.options;    
      if( !options.debug ) return false;
      if( typeof console !== 'undefined' && typeof console.log !== 'undefined' ){      
        if( options.show.setup ){
          var a = arguments.toArray(),
              t = this.ready? 'DSB Log' : 'JS Setup',
          //trace = ( (new Error).stack.chop("\n")[4] || 'could not trace code line origin' );
          trace = 'trace disabled';
          a[0] = '[ '+t+' ]: '+ a[0];// + ' ('+ trace +')';
          console.log.apply ? console.log.apply(null,a) : console.log(a);
        }
      }
    },    


    /*
     * @method error
     * @param message {Any}
     * generic error function
     */

    error: function(e){ if( console && console.error ) console.error('[ Error ] : '+e); },


    /* 
     * @method info
     * @param message {Any}
     * generic information function
     */

    info: function(i){ if( console && console.info ) console.info('[ Info ] : '+i); },


/*----------------------------------------------------------------------------*/


    dummy: function(){
      var a = arguments.toArray(),
      t = [],
      s = 'Found %d arguments: [ %w ]';
      a.each(function(i){ t.push( i.is() ); });
      dsb.log('Firing Dummy:: '+s,a.length,t);
    },


/******************************************************************************/
// [ private methods for setup ]
/******************************************************************************/


  /* 
   * @private
   * @method integrate
   * @param {string} path
   * @param {array|string} item 
   * Adds a JS file to be loaded
   */

  _integrate: function(path,item){
    var add = dsb.loader.add,
    path = dsb.self + path;    
    if( typeof item !== 'undefined' ){
      if( item.is('array') || item.is('object') ) item.each(function(script){ add(  path + script ); });
      if( item.is('string') ) add( path + item );      
    } else throw new Error('Invalid Script to integrate');    
  },


  _commands: [],
  
  
  _scan: function(){
    var cmd = document.getElementsByTagName('dsb'), l = cmd.length;
    if( l ){
      dsb.log( 'Found %d commands', l );
      cmd.each(function(c){          
        if( c && c.getAttribute ) dsb._commands.push( c.getAttribute('command') );          
      });
      dsb.log('Commands: '+dsb._commands);
    }
  },
  
  
  _hooks: {
    'change': function(){
      var a = arguments.toArray(),
      area = a.unshift();
      //alert(a.printr);
      switch(area){
        case 'backdrop':
          alert('found backdrop');
          dsb.frame.backdrop.change.apply(null,a);
        break;
      }
    }
  },
  
  _execute: function(){
    dsb._commands.each(function(command){
      var cmd, target, data;            
      if( command.is('string') ) command = command.split(' ');      
      if( command.is('array') ){
        cmd = command.shift();
        //$$$=command
        var hooks = dsb._hooks;        
        if( hooks.has(cmd) ){
          var c = hooks[cmd];
          //alert('found '+typeof command);          
          if( c.is('function') ) c(command);
        }
      }     
      
    });
  },
  
  
  /* 
   * @method ready
   * @private
   */
  
  _ready: function(){ 
    dsb.ready = true;
    dsb.event.trigger('ready');
  },

  
  _process_queue: function(){
    dsb.queue.process(function(fn){
      dsb.log('processing fn '+typeof fn);
      fn();
    });            
  },
  
  
  
  _setup: function(){
    
    var dsb = this;
    //dsb._integrate( './scripts/', dsb.dependencies );
    //dsb.loader.start(function(){
    dsb._scan();
    dsb._execute();
    dsb.frame.setup();
    dsb._process_queue();
    dsb.frame.activate();

    dsb.logger.setup( dsb.options.logger );
    dsb.log = dsb.logger.report;

    dsb._ready();

  },  

    
  /*
  * @method relay
  * @param callback,..args
  * accepts a function. will call immediately if dsb is ready, queue if not
  */

  relay: function(){
    var dsb = this, a = arguments.toArray(), cb = a.shift(), method = false;
    //if function, bind with arguments
    if( typeof cb === 'function' ){
      method = cb.bind(dsb);
      if( a.length ) a.each(function(v){ method.bind(v); });
    } else return;
    //call if dsb.ready, store otherwise
    if( dsb.ready ) method();    
    else dsb.queue.add( method );    
  },
  
  
  /*
   * @method start
   * the public method for starting (initializing) the DSB
   * @param {type} cb
   * @return {Boolean}
   */
  
  start: function(options){
    var dsb = this;
    if( !dsb.ready ){      
      dsb._setup( dsb.merge( options || {} ) );
      if( options && options.global ) window ? window.dsb = dsb : global.dsb = dsb;
      return false;
    }
  }

/*----------------------------------------------------------------------------*/
  
}; //end of dsb  

/******************************************************************************/

  return dsb;

/*----------------------------------------------------------------------------*/
}(0));